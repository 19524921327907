import { Component, OnInit, ChangeDetectorRef, HostListener, NgZone, NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subscription } from 'rxjs';
import { RouterExtService } from 'src/app/services/router-ext-service.service';
import { BibliService } from '../../services/bibli.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { ViewChild } from '@angular/core';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-bibli',
  templateUrl: './bibli.component.html',
  styleUrls: ['./bibli.component.scss']
})
export class BibliComponent implements OnInit {
  public isDroppedDown = 0;
  public bookCatalog
  public bookCatalog2 = []
  public lastDoc
  public focusCatalog
  public authorLabels = ['Tout', 'A tagger']
  public condensedView = false;
  public numberOfBooks = 0
  public loading = false
  public ableScrolling = true
  public loadTime = 1000
  public isFocused = false
  public focusedAuthor = ""
  public vue = "Condenser"
  public focusedOnRecent = false
  public scrollSubscription = Subscription.EMPTY;
  public scrollThreshold = 0.45
  public scrollStep = 0.06
  public scrollRatioMax = 0.89
  public showFavorites = false

  // Get scrollbar component reference
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  constructor(public auth: AngularFireAuth, private bibli : BibliService, private ref: ChangeDetectorRef, private routerExtService: RouterExtService, private zone: NgZone) {}

  ngAfterViewInit() {
    // Subscribe to scroll event
    this.scrollSubscription = this.scrollbarRef.scrolled.pipe(
      map((e: any) => e.target.scrollTop / e.target.scrollHeight > this.scrollThreshold ? 'yes' : 'no'),
      tap((above50: string) => this.zone.run(() => {
        if(above50 === 'yes'){
          this.onScroll()
        }
      }))
    ).subscribe();
  }
  
  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.ableScrolling = false
    let initBooks = this.bibli.getInitBooks().toPromise().then(async querySnapshot => {
      let userEmail = (await this.auth.currentUser).email
      querySnapshot.forEach(async (doc) => {
        let newData = doc.data()
        newData['customId'] = doc.id
        newData['delay'] = Math.random()
        newData['favorite'] = (typeof newData['favorite'] !== 'undefined')?newData['favorite'].includes(userEmail):false
        this.bookCatalog2.push(newData)
      })
      // this.fillWithAnimationDelays(this.bookCatalog2)
      this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
      this.focusCatalog = this.bookCatalog2
      let prevUrl = this.routerExtService.getPreviousUrl()
      this.focusedAuthor = sessionStorage.getItem("focus");
      this.ableScrolling = true
      if(this.focusedAuthor && (prevUrl.includes("editLivre") || prevUrl.includes("bibliotheque/"))){
        this.ableScrolling = false
        this.focusOn(this.focusedAuthor)
      } else if (prevUrl.includes("uploadLivres")){
        this.focusOn('Recent')
      }
    })
    let bibliData = this.bibli.getBlibliData().subscribe((doc : any) => {
      this.numberOfBooks = doc.nbBooks
      this.authorLabels = this.sortArray(doc.authorList)
      this.ref.detectChanges()
    })
    this.ref.detectChanges()
  }

  getMoreBooks() {
    this.showFavorites = false
    if(this.focusedOnRecent) {
      let moreBooks = this.bibli.getMoreRecentBooks(this.lastDoc).toPromise().then(async querySnapshot => {
      let userEmail = (await this.auth.currentUser).email
        querySnapshot.forEach((doc) => {
          let newData = doc.data()
          newData['customId'] = doc.id
          newData['delay'] = Math.random()
          newData['favorite'] = (typeof newData['favorite'] !== 'undefined')?newData['favorite'].includes(userEmail):false
          this.focusCatalog.push(newData)
        })
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
        this.loading = false
      })
    } else {
      let moreBooks = this.bibli.getNextBooks(this.lastDoc).toPromise().then(async querySnapshot => {
      let userEmail = (await this.auth.currentUser).email
        querySnapshot.forEach((doc) => {
          let newData = doc.data()
          newData['customId'] = doc.id
          newData['delay'] = Math.random()
          newData['favorite'] = (typeof newData['favorite'] !== 'undefined')?newData['favorite'].includes(userEmail):false
          this.bookCatalog2.push(newData)
        })
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
        this.focusCatalog = this.bookCatalog2
        this.loading = false
      })
    }
  }

  refreshDropDown(){
    this.ref.detectChanges()
  }

  focusOn(zeFocus){
    this.showFavorites = false
    this.isFocused = false
    this.focusedAuthor = ""
    this.ableScrolling = false
    this.focusedOnRecent = false
    if(zeFocus === "Tout"){
      sessionStorage.removeItem("focus");
      this.focusCatalog = this.bookCatalog2
      this.ableScrolling = true
      this.ref.detectChanges()
    } else if (zeFocus === "Recent") {
      sessionStorage.setItem("focus", zeFocus)
      this.focusCatalog = []
      this.ableScrolling = true
      this.focusedOnRecent = true
      this.bibli.getRecentBooks().toPromise().then(async querySnapshot => {
      let userEmail = (await this.auth.currentUser).email
        querySnapshot.forEach((doc) => {
          let newData = doc.data()
          newData['customId'] = doc.id
          newData['delay'] = Math.random()
          newData['favorite'] = (typeof newData['favorite'] !== 'undefined')?newData['favorite'].includes(userEmail):false
          this.focusCatalog.push(newData)
          this.ref.detectChanges()
        })
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
      })
    }else {
      this.isFocused = true
      this.focusedAuthor = zeFocus
      sessionStorage.setItem("focus", this.focusedAuthor)
      this.focusCatalog = []
      this.bibli.getBooksByAuthor(zeFocus).toPromise().then(async querySnapshot => {
      let userEmail = (await this.auth.currentUser).email
        querySnapshot.forEach((doc) => {
          let newData = doc.data()
          newData['customId'] = doc.id
          newData['delay'] = Math.random()
          newData['favorite'] = (typeof newData['favorite'] !== 'undefined')?newData['favorite'].includes(userEmail):false
          this.focusCatalog.push(newData)
          this.ref.detectChanges()
        })
      })
    }
  }

  async getFavorites(){
    this.showFavorites = true
    this.ableScrolling = false
    let userEmail = (await this.auth.currentUser).email
    this.isFocused = true
    this.focusCatalog = []
    this.ref.detectChanges()
    this.bibli.getFavoriteUsers(userEmail).toPromise().then(async querySnapshot => {
      let userEmail = (await this.auth.currentUser).email
      querySnapshot.forEach((doc) => {
        let newData = doc.data()
        newData['customId'] = doc.id
        newData['delay'] = Math.random()
        newData['favorite'] = (typeof newData['favorite'] !== 'undefined')?newData['favorite'].includes(userEmail):false
        this.focusCatalog.push(newData)
        this.ref.detectChanges()
      })
    })
  }

  switchView(){
    this.condensedView = !this.condensedView
    this.condensedView ? this.bibli.pageSize = 20 : this.bibli.pageSize = 10
    this.condensedView ? this.loadTime = 300 : this.bibli.pageSize = 800
    this.condensedView ? this.vue = "Etendre" : this.vue = "Condenser"
    this.ref.detectChanges()
  }

  sortArray(array){
    array.sort(function(a, b){
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    })
    return array
  }

  sortArrayByBookTitle(array){
    array.sort(function(a, b){
      if(a.bookTitle < b.bookTitle) { return -1; }
      if(a.bookTitle > b.bookTitle) { return 1; }
      return 0;
    })
    return array
  }

  onScroll(){
    if (this.ableScrolling){
      if(!this.loading) {
        this.loading = true
        this.scrollThreshold = (this.scrollThreshold + this.scrollStep) > this.scrollRatioMax ? this.scrollRatioMax : Math.min(this.scrollThreshold + this.scrollStep, this.scrollRatioMax)
        setTimeout(() => {
          this.getMoreBooks();
        }, this.loadTime);
      }
    }
  }

  aleatoire(){
    let item = this.authorLabels[Math.floor(Math.random() * this.authorLabels.length)];
    this.focusOn(item)
  }

  showPanel(){
    let menu = document.getElementById("focusMenu");
    menu.classList.add("toggleMenu")
    this.isDroppedDown = 1
  }

  checkFocus(){
    if(this.isDroppedDown == 1){
      this.hidePanel()
    } else {
      this.showPanel()
    }
  }

  hidePanel(){
    let menu = document.getElementById("focusMenu");
    menu.classList.remove("toggleMenu")
    this.isDroppedDown = 0
  }

  getClassHeaderBibli(){
    if (!this.isFocused){
      return ({'stickyHeaderBibli' : true})
    }
  }

  fillWithAnimationDelays(array){
    array.forEach(el => {
      el['delay'] = Math.random()
    })
   }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if(targetElement.id !== "dropdownButton"){
      this.hidePanel()
    }
  }
}
