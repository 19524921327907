// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyASKSWSXdbOm5NVW9NbAzJ1isG9tJ0zSfQ",
    authDomain: "bitbot-b9cc2.firebaseapp.com",
    databaseURL: "https://bitbot-b9cc2.firebaseio.com",
    projectId: "bitbot-b9cc2",
    storageBucket: "bitbot-b9cc2.appspot.com",
    messagingSenderId: "545687721849",
    appId: "1:545687721849:web:8f363743da345af44d01a8",
    measurementId: "G-NLZWL3HK8M"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
