import { Component, NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NgScrollbar } from 'ngx-scrollbar';
import firebase from 'firebase/compat/app'
import { ColorPreferenceService } from 'src/app/services/color-preference.service';
import { BibliService } from '../../services/bibli.service';
import { FunctionsService } from '../../services/functions.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})

export class SigninComponent {
  public userHistory = []
  public showHistory = false
  public displayShowHistory = "Voir mon historique"
  public colorMode = 'light'

  constructor(private fns : AngularFireFunctions, public auth: AngularFireAuth, public fnc : FunctionsService, public bibli : BibliService, public colorPref : ColorPreferenceService) {
    colorPref.colorMode.subscribe(data => this.colorMode = data)
    console.log('sign in' , this.colorMode)
    colorPref.checkInitialColorMode()
  }

  login() {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
    .then(async ()=>{
      this.fnc.loggedIn = true;
      sessionStorage.setItem("user", "ok")
      let currentUser = (await this.auth.currentUser)
      let displayName = currentUser.displayName
      const callable = this.fns.httpsCallable('bookInfo');
      let paramMail = {
        message : `${displayName} s'est connecté !`
      }
      callable(paramMail).subscribe(data => {
    })
    });
  }

  logout() {
    this.auth.signOut()
    .then(()=>{
      this.fnc.loggedIn = false;
      sessionStorage.removeItem("user")
    });
  }

  async fillHistory() {
    if(!this.showHistory){
    let currentUser = (await this.auth.currentUser)
    let userEmail = currentUser.email
    this.bibli.getBibliHistory(userEmail).subscribe(data => {
        this.userHistory = data['history']
        this.userHistory.sort((a, b) => b.date.toMillis() - a.date.toMillis())
        this.showHistory = true
        this.displayShowHistory = "Cacher l'historique"
      })
    } else {
      this.showHistory = false
      this.displayShowHistory = "Voir mon historique"
    }
  }


}