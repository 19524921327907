import { Component,  NgZone, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';


@Component({
  selector: 'app-new-datapoint-deni',
  templateUrl: './new-datapoint-deni.component.html',
  styleUrls: ['./new-datapoint-deni.component.scss']
})
export class NewDatapointDeniComponent implements OnInit {
  public dataForm
  public reussi = false

  dateFormat = "yyyy-MM-dd";
  language = "en";
  
  constructor(private formBuilder: FormBuilder, private firestore: AngularFirestore, private ngZone:NgZone, private router : Router) {
    this.dataForm = this.formBuilder.group({
      date: new Date().toISOString().substring(0, 10),
      current_account : 0,
      savings_account: 0,
      pension_savings:0,
      sap_stock: 0,
      savings_property: 0,
      action_revolut: 0,
    });
  }


  ngOnInit(): void {
    (<HTMLInputElement>document.getElementById('datePicker')).valueAsDate = new Date();
  }

  onSubmit(dataPoint) {
    let test = this.firestore.collection('epargne_history_deni')
    test.add({
      date: new Date(dataPoint.date),
      current_account: dataPoint.current_account,
      savings_account: dataPoint.savings_account,
      sap_stock: dataPoint.sap_stock,
      pension_savings: dataPoint.pension_savings,
      savings_property : dataPoint.savings_property,
      action_revolut : dataPoint.action_revolut,
    }).then(() => {
      this.reussi = true
      setTimeout( () => {
        this.ngZone.run(()=>this.router.navigate(['/moneyDeni']))
      }, 1000 );
      
    })
  }
}
