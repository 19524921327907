<h2>Gestion des accès</h2>
<p class="normalP centerP">Indiquer l'ici le User Id de la personne à laquelle accorder un droit d'accès bibliothèque</p>
<div class="mainContent">
    <form [formGroup]="dataForm" (ngSubmit)="onSubmit(dataForm.value)">
        <fieldset class="mainForm">
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">User Id</span>
            </div>
            <input id="uid" name="uid" type="text" class="userInput" formControlName="uid">
        </fieldset>
        <div class="buttonBottom">
            <button type="submit" class="btnPerso btnSecondary leftButton">Accorder le droit</button>
            <button type="reset" routerLink="/auth" class="btnPerso btnSecondaryLight">Annuler</button>
        </div>
    </form>
    <div *ngIf="showReply">
        <p class="normalP">{{reply}}</p>
    </div>
</div>