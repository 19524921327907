<head>
    <title>TCarval</title>
  </head>
<body>
  <app-navbar></app-navbar>
  <div class="superMainComponent">
    <router-outlet></router-outlet>
  </div>
  <div class="clear"></div>
  <app-navfooter></app-navfooter>
</body>
