import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pepite',
  templateUrl: './pepite.component.html',
  styleUrls: ['./pepite.component.scss']
})
export class PepiteComponent implements OnInit {
  
  @Input() date : string;
  @Input() illustration : string;
  @Input() location : string;
  @Input() owner : string;
  @Input() randNumber : string;
  @Input() description : string;
  @Input() title : string;
  @Input() type:string;
  @Input() customId:string;

  constructor() { }

  ngOnInit(): void {
  }

  getTitleClass(){
    if (this.title.length > 15 && this.title.length <= 30 ){
      return ({'smallerTitle' : true})
    } else if (this.title.length > 25){
      return ({'muchSmallerTitle' : true})
    }
  }
}
