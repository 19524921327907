import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { arrayUnion } from '@angular/fire/firestore'


@Injectable({
  providedIn: 'root'
})
export class CzechService {

  constructor(private fire : AngularFirestore) { }

  getCategories(){
    return this.fire.collection("czech_vocab").get()
  }

  getCategoryById(id){
    return this.fire.doc("czech_vocab/" + id).valueChanges()
  }

  writeNewWords(catId, newWordsList){
    newWordsList.forEach(el => {
      this.fire.doc("czech_vocab/" + catId).update({
        vocab : arrayUnion(el)
      })
    })
  }

  createCategory(catNameFr, catNameCz, wordsList){
    let test = this.fire.collection('czech_vocab')
    test.add({
      titleFR: catNameFr,
      titleCZ: catNameCz,
      vocab: wordsList
    })
  }
}
