import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CzechService } from 'src/app/services/czech.service';
import { FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-vocab',
  templateUrl: './add-vocab.component.html',
  styleUrls: ['./add-vocab.component.scss']
})
export class AddVocabComponent implements OnInit {
  public categoryList = []
  public dataForm = this.formBuilder.group({
    category : '',
    listVocab : '',
    fr : '',
    cz : '',
    newCategoryFR : '',
    newCategoryCZ : ''
  })
  public newVocab = false;
  public newVocabList = ""
  public wordsToAdd = []
  public showError = false;

  constructor(private router : Router, public cz : CzechService, private formBuilder: FormBuilder, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cz.getCategories().toPromise().then(querySnapshot => {
      querySnapshot.forEach((doc) => {
        let newData = doc.data()
        newData['customId'] = doc.id
        this.categoryList.push(newData)
      })
      this.categoryList.sort((a,b)=>(a['titleFR'] > b['titleFR']) ? 1 : ((b['titleFR'] > a['titleFR']) ? -1 : 0))
    })
    this.onChange()
  }

  resetForm(){
    this.dataForm.controls['fr'].setValue('')
    this.dataForm.controls['cz'].setValue('')
    this.dataForm.controls['listVocab'].setValue('')
  }

  onChange() {
    this.dataForm.get('listVocab').valueChanges.subscribe(val => {
      val.length > 0 ? this.newVocab = true : this.newVocab = false
      this.checkNewVocab(val, '', '')
      this.ref.detectChanges()
    })
  }

  checkNewVocab(val, fr, cz){
    let newWords = []
    this.wordsToAdd = []
    this.newVocabList = ""
    if(val.length > 0){
      let wordCouples = val.split(/\r?\n/)
      wordCouples.forEach(el => {
        let wordCouple = el.split(";")
        let couple;
        if (wordCouple.length == 2 && wordCouple[0].length > 0 && wordCouple[1].length > 0) {
          couple = {
            fr : wordCouple[0],
            cz : wordCouple[1],
            date : new Date()
          }
          this.wordsToAdd.push(couple)
        } else {
          couple = {
            fr : "/!\\",
            cz : "/!\\",
            date : new Date()
          }
        }
        newWords.push(couple)
        this.newVocabList = this.newVocabList + `fr : ${couple.fr} | cz : ${couple.cz}\n`
      })
    } else if (fr.length > 0 && cz.length > 0){
        let couple;
        couple = {
        fr : fr,
        cz : cz,
        date : new Date()
      }
      this.wordsToAdd.push(couple)
      newWords.push(couple)
      this.newVocabList = this.newVocabList + `fr : ${couple.fr} | cz : ${couple.cz}\n`
    }
  }

  getIdByCategoryTitle(title){
    let newTitle = title
    if(title == ""){
      newTitle = "Bordel"
    }
    for(let i = 0; i < this.categoryList.length ; i ++){
      let el = this.categoryList[i]
      if(el.titleFR == newTitle){
        return el.customId
      }
    }
  }

  onSubmit(dataPoint){
    this.checkNewVocab(dataPoint.listVocab, dataPoint.fr, dataPoint.cz)
    if(dataPoint.newCategoryFR.length > 0 && dataPoint.newCategoryCZ.length > 0){
      this.cz.createCategory(this.toTitleCase(dataPoint.newCategoryFR), this.toTitleCase(dataPoint.newCategoryCZ), this.wordsToAdd)
      this.resetForm()
      // this.router.navigate(['/czech'])
    } else if (dataPoint.newCategoryFR.length == 0 && dataPoint.newCategoryCZ.length == 0){
      this.cz.writeNewWords(this.getIdByCategoryTitle(dataPoint.category), this.wordsToAdd)
      this.resetForm()
      // this.router.navigate(['/czech'])
    } else {
      this.showError = true
    }
  }
  toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }

}
