import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-add-claims',
  templateUrl: './add-claims.component.html',
  styleUrls: ['./add-claims.component.scss']
})
export class AddClaimsComponent implements OnInit {
  public dataForm = this.formBuilder.group({
    uid : ""
  })
  public showReply = false
  public reply = ""

  constructor(private fns: AngularFireFunctions, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

  onSubmit(dataPoint) {
    let dict = {
      uid : dataPoint.uid
    }
    let accessFunction = this.fns.httpsCallable('giveBibliAccess');
    // let accessFunction = this.fns.httpsCallable('giveBigAccess');
    accessFunction(dict).subscribe(result => {
      this.showReply = true
      this.reply = result.msg
    })
  }

}
