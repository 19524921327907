import  { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
// import { first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable()
export class FunctionsService{
  public hasUpdatedTransactionHistory : boolean = false
  public hasUpdatedOpenOrders : boolean = false
  public hasUpdatedBalance: boolean = false
  public hasUpdatedTicker: boolean = false
  private user;
  public loggedIn = false

  constructor(public auth: AngularFireAuth, private fns: AngularFireFunctions) {
    this.auth.user.subscribe(data => this.user = data)
    }

  getUser(){
    return this.user
  }

  getUserPic(){
    let curUser = (this.getUser())
    if(curUser !== null && (typeof curUser !== 'undefined')){
      return this.user.photoURL
    } else{
      return 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png'
    }
    // if (this.user) {
    //   return this.user.photoURL
    // } else {
    //   return 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png'
    // }
  }



  // isLoggedIn() {
  //   this.auth.authState.pipe()
  //   return this.auth.authState.pipe;
  // }

  updateTickerOnce(){
    if(!this.hasUpdatedTicker){
      this.hasUpdatedTicker = true
      console.log('update ticker')
      this.fns.httpsCallable('getExceptionnalHourlyXRPTicker')({text : "some text"})
      .subscribe((resp) => {
      });
    }
  }

  updateTransationHistoryOnce(){
    if(this.hasUpdatedTransactionHistory){
      this.hasUpdatedTransactionHistory = true
      console.log("update transaction history")
      this.fns.httpsCallable('updateTransactionHistory')({text : "some text"})
      .subscribe((resp) => {
      });
    }
  }

  updateOpenOrdersOnce(){
    if(!this.hasUpdatedOpenOrders){
      this.hasUpdatedOpenOrders = true
      console.log("update open orders")
      this.fns.httpsCallable('updateOpenOrders')({text : "some text"})
        .subscribe((resp) => {
        });
    }
  }

  updateBalanceOnce(){
    if(!this.hasUpdatedBalance){
      this.hasUpdatedBalance = true
      console.log("update balance")
      this.fns.httpsCallable('updateBalance')({text : "some text"})
        .subscribe((resp) => {
        });
    }
  }
}