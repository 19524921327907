import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';
import { ChartHistoryComponent } from './lazyCrypto/chart-history/chart-history.component';
import { CommonModule } from '@angular/common';
import { ChartService } from './services/chart.service';
import { HomePageComponent } from './lazyEpargne/home-page/home-page.component';
import { Routes, RouterModule, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { FunctionsService } from './services/functions.service';
import { ChartTickerComponent } from './lazyCrypto/chart-ticker/chart-ticker.component';
import { SigninComponent } from './lazyMainPage/signin/signin.component';
import { FourOhFourComponent } from './lazyMainPage/four-oh-four/four-oh-four.component';
import { TickerComponent } from './lazyCrypto/ticker/ticker.component';
import { CreateDataComponent } from './lazyEpargne/create-data/create-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoanSimulatorComponent } from './lazyEpargne/loan-simulator/loan-simulator.component';
import { DropzoneDirective } from './lazyBibliotheque/directives/dropzone.directive';
import { UploaderComponent } from './lazyBibliotheque/uploader/uploader.component';
import { UploadTaskComponent } from './lazyBibliotheque/upload-task/upload-task.component';
import { EditBibliComponent } from './lazyBibliotheque/edit-bibli/edit-bibli.component';
import { BibliComponent } from './lazyBibliotheque/bibli/bibli.component';
import { BookComponent } from './lazyBibliotheque/book/book.component';
import { BookDetailComponent } from './lazyBibliotheque/book-detail/book-detail.component';
import { CondensedBookComponent } from './lazyBibliotheque/condensed-book/condensed-book.component';
import { AccueilComponent } from './lazyMainPage/accueil/accueil.component';
import { AddClaimsComponent } from './lazyMainPage/add-claims/add-claims.component';
import { RouterExtService } from './services/router-ext-service.service';
import { LiveTVComponent } from './lazyRugby/live-tv/live-tv.component';
import { MatchComponent } from './lazyRugby/match/match.component';
import { HttpClientModule } from '@angular/common/http';
import { SafePipe } from './safe.pipe';
import { CzechVocabComponent } from './lazyCzech/czech-vocab/czech-vocab.component';
import { VocabCategoryComponent } from './lazyCzech/vocab-category/vocab-category.component';
import { VocabWordComponent } from './lazyCzech/vocab-word/vocab-word.component';
import { AddVocabComponent } from './lazyCzech/add-vocab/add-vocab.component';
import { RandomVocabComponent } from './lazyCzech/random-vocab/random-vocab.component';
import { NavbarComponent } from './lazyMainPage/navbar/navbar.component';
import { NgAisModule } from 'angular-instantsearch';
import { SearchBooksComponent } from './lazyBibliotheque/search-books/search-books.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NavfooterComponent } from './lazyMainPage/navfooter/navfooter.component';
import { PepiteHomeComponent } from './lazyPepite/pepite-home/pepite-home.component';
import { PepiteComponent } from './lazyPepite/pepite/pepite.component';
import { PepiteCreationComponent } from './lazyPepite/pepite-creation/pepite-creation.component';
import { PepiteDetailComponent } from './lazyPepite/pepite-detail/pepite-detail.component';
import { EditPepiteComponent } from './lazyPepite/edit-pepite/edit-pepite.component';
import {CanActivate} from "@angular/router";
import { AngularFireAuth } from '@angular/fire/compat/auth';

import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { first, Observable, take, map, lastValueFrom } from 'rxjs';
import { idToken, user } from 'rxfire/auth';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { EpargneDeniComponent } from './lazyEpargne/epargne-deni/epargne-deni.component';
import { NewDatapointDeniComponent } from './lazyEpargne/new-datapoint-deni/new-datapoint-deni.component';

PlotlyModule.plotlyjs = PlotlyJS;

class AlwaysAuthGuard implements CanActivate {
  canActivate() {
    return true;
  }
}

@Injectable()
class OnlyLoggedInUsersGuard implements CanActivate {
  constructor(private fnc: FunctionsService, private router: Router) {}; 

  canActivate() {
    if (this.fnc.getUser() !== null && this.fnc.getUser().email.length > 5) {
      return true;
    } else {
      this.router.navigateByUrl('auth');
      // window.alert("You don't have permission to view this page");
      return false;
    }
  }
}

@Injectable()
class AdminOnlyGuard implements CanActivate {
  constructor(private auth: AngularFireAuth, private router: Router) {}; 

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Observable<boolean>(obs => {
        this.auth.idTokenResult.subscribe(token => {
          console.log(token.claims.firebase.identities.email[0])
          if(token.claims.admin || token.claims.firebase.identities.email[0] == 'denisamifkova@gmail.com' || token.claims.firebase.identities.email[0] == 'deni.mifkova@gmail.com'){
            obs.next(true);
            } else {
              this.router.navigateByUrl('auth');
              obs.next(false);
            }
        })
      })
  }
}

@Injectable()
class AdminOrBibliOnlyGuard implements CanActivate {
  constructor(private auth: AngularFireAuth, private router: Router) {}; 

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Observable<boolean>(obs => {
        this.auth.idTokenResult.subscribe(token => {
          if(token.claims.admin || token.claims.accessBibli){
            obs.next(true);
            } else {
              this.router.navigateByUrl('auth');
              obs.next(false);
            }
        })
      })
  }

}

// const adminOnly = () => hasCustomClaim('admin');
// const adminOnlyOrRedirectToLogin = () => pipe(customClaims, map(claims => claims.admin ? true : ['auth']));
// const accessBibliOnlyOrRedirectToLogin = () => pipe(customClaims, map(claims => (claims.accessBibli || claims.admin) ? true : ['auth']));
// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);

const appRoutes : Routes = [
  {path : 'cryptoFocus', component : ChartHistoryComponent , canActivate: [AdminOnlyGuard]},
  {path : 'money', component : HomePageComponent, canActivate: [AdminOnlyGuard]},
  {path : 'moneyDeni', component : EpargneDeniComponent, canActivate: [AdminOnlyGuard]},
  {path : 'createdata', component : CreateDataComponent, canActivate: [AdminOnlyGuard]},
  {path : 'createdataDeni', component : NewDatapointDeniComponent, canActivate: [AdminOnlyGuard]},
  {path : 'loan', component : LoanSimulatorComponent, canActivate: [AdminOnlyGuard]},
  {path : 'ticker', component : TickerComponent, canActivate: [AdminOnlyGuard]},
  {path : 'manageClaims', component : AddClaimsComponent, canActivate: [AdminOnlyGuard]},
  {path : 'liveRugby', component : LiveTVComponent , canActivate: [AdminOnlyGuard]},
  {path : 'bibliotheque', component : BibliComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'bibliotheque/:id', component : BookDetailComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'bibliothequeSearch', component : SearchBooksComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'uploadLivres', component : UploaderComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'editLivre/:id', component : EditBibliComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'czech', component : CzechVocabComponent, canActivate: [OnlyLoggedInUsersGuard]},
  {path : 'addVocab', component : AddVocabComponent, canActivate: [OnlyLoggedInUsersGuard]},
  {path : 'randomVocab', component : RandomVocabComponent, canActivate: [OnlyLoggedInUsersGuard]},
  {path : 'czech/:id', component : VocabCategoryComponent, canActivate: [OnlyLoggedInUsersGuard]},
  {path : 'pepiteHome', component : PepiteHomeComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'pepiteCreation', component : PepiteCreationComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'pepite/:id', component : PepiteDetailComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'editPepite/:id', component : EditPepiteComponent, canActivate: [AdminOrBibliOnlyGuard]},
  {path : 'home', component : AccueilComponent},
  {path : 'auth', component : SigninComponent},
  {path : '', component : AccueilComponent},
  {path : 'not-found', component : FourOhFourComponent},
  {path : '**', redirectTo : 'not-found'},
  // {path : 'cryptoFocus', component : ChartHistoryComponent , canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnlyOrRedirectToLogin }},
  // {path : 'money', component : HomePageComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnlyOrRedirectToLogin }},
  // {path : 'createdata', component : CreateDataComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnlyOrRedirectToLogin }},
  // {path : 'loan', component : LoanSimulatorComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnlyOrRedirectToLogin }},
  // {path : 'ticker', component : TickerComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnlyOrRedirectToLogin }},
  // {path : 'manageClaims', component : AddClaimsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnlyOrRedirectToLogin }},
  // {path : 'liveRugby', component : LiveTVComponent , canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnlyOrRedirectToLogin }},
  // {path : 'bibliotheque', component : BibliComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'bibliotheque/:id', component : BookDetailComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'bibliothequeSearch', component : SearchBooksComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'uploadLivres', component : UploaderComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'editLivre/:id', component : EditBibliComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'czech', component : CzechVocabComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  // {path : 'addVocab', component : AddVocabComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  // {path : 'randomVocab', component : RandomVocabComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  // {path : 'czech/:id', component : VocabCategoryComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  // {path : 'pepiteHome', component : PepiteHomeComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'pepiteCreation', component : PepiteCreationComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'pepite/:id', component : PepiteDetailComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'editPepite/:id', component : EditPepiteComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accessBibliOnlyOrRedirectToLogin }},
  // {path : 'home', component : AccueilComponent},
  // {path : 'auth', component : SigninComponent},
  // {path : '', component : AccueilComponent},
  // {path : 'not-found', component : FourOhFourComponent},
  // {path : '**', redirectTo : 'not-found'},
]

@NgModule({
  declarations: [
    AppComponent,
    ChartHistoryComponent,
    HomePageComponent,
    ChartTickerComponent,
    SigninComponent,
    FourOhFourComponent,
    TickerComponent,
    CreateDataComponent,
    LoanSimulatorComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    EditBibliComponent,
    BibliComponent,
    BookComponent,
    BookDetailComponent,
    CondensedBookComponent,
    AccueilComponent,
    AddClaimsComponent,
    LiveTVComponent,
    MatchComponent,
    SafePipe,
    CzechVocabComponent,
    VocabCategoryComponent,
    VocabWordComponent,
    AddVocabComponent,
    RandomVocabComponent,
    NavbarComponent,
    SearchBooksComponent,
    NavfooterComponent,
    PepiteHomeComponent,
    PepiteComponent,
    PepiteCreationComponent,
    PepiteDetailComponent,
    EditPepiteComponent,
    EpargneDeniComponent,
    NewDatapointDeniComponent,
  ],
  exports: [
    MatchComponent,
    PepiteComponent,
    NavbarComponent,
    NavfooterComponent,
    BookComponent,
    VocabWordComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ChartTickerComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    RouterModule,
    HttpClientModule,
    NgScrollbarModule,
    PlotlyModule,
    NgAisModule.forRoot()
  ],
  providers: [
    ChartService,
    FunctionsService,
    RouterExtService,
    AlwaysAuthGuard,
    OnlyLoggedInUsersGuard,
    AdminOnlyGuard,
    AdminOrBibliOnlyGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private routerExtService: RouterExtService){}
}

