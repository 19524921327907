import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PepiteService } from 'src/app/services/pepite.service';

@Component({
  selector: 'app-edit-pepite',
  templateUrl: './edit-pepite.component.html',
  styleUrls: ['./edit-pepite.component.scss']
})
export class EditPepiteComponent implements OnInit {
  public dataForm = this.formBuilder.group({
    illustration : [''],
    location : [''],
    description : [''],
    title : [''],
    type : [''],
  })
  illustration : string;
  location : string;
  description : string;
  title : string;
  type:string;
  customId:string;

  constructor(private ngZone:NgZone, private router : Router, private pep : PepiteService, private formBuilder: FormBuilder, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.customId  = this.route.snapshot.params['id'];
    this.pep.getPepitebyId(this.customId).subscribe((data : any)=> {
      console.log(data)
      if(data.hasOwnProperty("illustration")) {this.illustration = data.illustration}
      if(data.hasOwnProperty("location")) {this.location = data.location}
      if(data.hasOwnProperty("description")) {this.description = data.description}
      if(data.hasOwnProperty("title")) {this.title = data.title}
      if(data.hasOwnProperty("type")) {this.type = data.type}
      this.dataForm.patchValue({
        illustration : this.illustration,
        location : this.location,
        description : this.description,
        title : this.title,
        type : this.type,
      })
    })
  }

  onSubmit(dataPoint) {
    let dict = {
      illustration : dataPoint.illustration,
      location : dataPoint.location,
      description : dataPoint.description,
      title : dataPoint.title,
      type : dataPoint.type,
    }
    this.pep.updatePepiteData(this.customId, dict).then(() => {
      this.ngZone.run(()=>this.router.navigate(['/pepiteHome']))
    })
  }

}
