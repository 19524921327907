import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterExtService } from 'src/app/services/router-ext-service.service';
import { BibliService } from '../../services/bibli.service';

@Component({
  selector: 'app-book-detail',
  templateUrl: './book-detail.component.html',
  styleUrls: ['./book-detail.component.scss']
})
export class BookDetailComponent implements OnInit {
  public customId;
  public bookName = ""
  public bookCover = ""
  public bookAuthor = ""
  public bookTitle = ""
  public bookSummary = ""
  public bookOwner = ""
  public downloadURL;
  public bookFormat;
  public mailSent = false;
  public wasSearched = false;
  public preSummaryString = "<span style='font-weight: 500;'>Résumé : </span><br />"
  
  constructor(private route: ActivatedRoute, private routerExtService: RouterExtService, private bibli : BibliService, private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.customId  = this.route.snapshot.params['id'];
    this.bibli.getBookById(this.customId).subscribe((data : any)=> {
      if(data.hasOwnProperty("bookName")) {this.bookName = data.bookName}
      if(data.hasOwnProperty("bookCover")) {this.bookCover = data.bookCover}
      if(data.hasOwnProperty("bookAuthor")) {this.bookAuthor = data.bookAuthor}
      if(data.hasOwnProperty("bookTitle")) {this.bookTitle = data.bookTitle}
      if(data.hasOwnProperty("bookOwner")) {this.bookOwner = data.bookOwner}
      if(data.hasOwnProperty("bookSummary")) {this.bookSummary = data.bookSummary}
      if(data.hasOwnProperty("downloadURL")) {this.downloadURL = data.downloadURL}
      if(data.hasOwnProperty("format")) {this.bookFormat = data.format}
      this.ref.detectChanges()
    })
    let prevUrl = this.routerExtService.getPreviousUrl()
    if(prevUrl.includes("Search")){
      this.wasSearched = true
    }
    this.ref.detectChanges()
  }


  mailRequested(){
    this.bibli.mailRequested(this.mailSent, this.bookTitle, this.bookAuthor, this.bookFormat, this.downloadURL, (result) => {
      console.log(result)
      if(result == "ok"){
        this.mailSent=true;
      }
    })
  }
  
  downloadBook(){
    window.open(this.downloadURL)
    this.bibli.logDownload(this.bookTitle)
  }

}
