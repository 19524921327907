<h2>Modifier les informations du livre</h2>
<div class="mainContent">
    <form [formGroup]="dataForm" (ngSubmit)="onSubmit(dataForm.value)">
        <fieldset class="mainForm">
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Nom</span>
            </div>
            <input id="bookName" name="bookName" type="text" formControlName="bookName" class="userInput">

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Auteur</span>
            </div>
            <input id="bookAuthor" name="bookAuthor" type="text" formControlName="bookAuthor" class="userInput">

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Titre</span>
            </div>
            <input id="bookTitle" name="bookTitle" type="text" formControlName="bookTitle" class="userInput">

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Format</span>
            </div>
            <input id="bookFormat" name="bookFormat" type="text" formControlName="bookFormat" class="userInput">

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Résumé</span>
            </div>
            <textarea rows = "5" id="bookSummary" name="bookSummary" type="text" formControlName="bookSummary" class="userInput">
            </textarea>
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">URL de couverture</span>
            </div>
            <input id="bookCover" name="bookCover" type="text" formControlName="bookCover" class="userInput">

        </fieldset>

        <div class="btnList">
            <button type="submit" class="btnPerso btnSecondary">Enregistrer</button>
            <button type="reset" routerLink="/bibliotheque" class="btnPerso btnGreyLight">Annuler</button>
        </div>
        
    </form>
</div>

<div class="btnCollapse">
    <button type="button" class="btnPerso btnPrimaryLight" (click)="collapse()">Aide à la recherche de couverture</button>
</div>
<div id="collapseExample">
    <div id="my-div" class="collapseDiv">
        <iframe id="my-iframe" width="100%" height="800" frameborder="0" allowfullscreen  [src]="searchURL | safe"></iframe>
    </div>
</div>