import { Component, Input, OnInit } from '@angular/core';

// @NgModule({
//   imports: [],
//   exports: [LiveTVComponent],
//   declarations: [],
//   providers: [],
// })
@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss']
})
export class MatchComponent implements OnInit {
  @Input() matchName : string;
  @Input() matchDateStr : string;
  @Input() matchRealDate;
  @Input() matchLink : string;
  @Input() matchLive : boolean;
  @Input() matchImg : string;
  @Input() matchLogos;
  @Input() matchDelay;
  matchPast = false;

  constructor() { }

  ngOnInit(): void {
    let TWO_HOUR = 2 * 60 * 60 * 1000;
    let dateTest = this.matchRealDate.toDate()
    let now = new Date()
    let dif = now.getTime() - dateTest.getTime()
    if (dif > 0 && dif < TWO_HOUR) {
      this.matchLive = true
    } else if (dif > TWO_HOUR) {
      this.matchPast = true
      this.matchLive = false
    } else {
      this.matchLive = false
    }
  }

  getClassImg(){
    if (this.matchPast){
      return ({'pastMatch card__img' : true})
    } else {
      return ({'card__img' : true})
    }
  }
  
}
