import { Component } from '@angular/core';
import { FunctionsService } from 'src/app/services/functions.service';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent {

  constructor(public fnc : FunctionsService) { }

  thereIsALoggedInUser(){
    let curUser = (this.fnc.getUser())
    if(curUser !== null && (typeof curUser !== 'undefined')){
      return true
    } else{
      return false
    }
    // return (typeof this.fnc.getUser() !== undefined && this.fnc.getUser().email.length > 5)
  }
  
  getClassLogin(){
    if (this.thereIsALoggedInUser()){
      return ({'gridItem notShow' : true})
    } else {
      return ({'gridItem' : true})
    }
  }
}
