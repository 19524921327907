<div [ngClass]="getClassHeaderBibli()">
    <div class="headerBibli">
        <h2 class="text-center" id="titreAccueil" (click)="focusOn('Tout')">Bibliothèque <span id="nbBooks">({{numberOfBooks}} 📚)</span></h2>
        <div class="buttonList">
            <button routerLink="/bibliothequeSearch" type="button" class="btnPerso btnSmall btnPrimary">
                <svg class="search"version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="49.999px" height="49.999px" viewBox="0 0 49.999 49.999" style="enable-background:new 0 0 49.999 49.999;"
                xml:space="preserve">
           <g>
               <g>
                   <path d="M48.681,42.295l-8.925-8.904c-0.045-0.045-0.098-0.078-0.145-0.11c-0.802,1.233-1.761,2.405-2.843,3.487
                       c-1.081,1.082-2.255,2.041-3.501,2.845c0.044,0.046,0.077,0.1,0.122,0.144l8.907,8.924c1.763,1.76,4.626,1.758,6.383,0
                       C50.438,46.921,50.439,44.057,48.681,42.295z"/>
                   <path d="M35.496,6.079C27.388-2.027,14.198-2.027,6.089,6.081c-8.117,8.106-8.118,21.306-0.006,29.415
                       c8.112,8.105,21.305,8.105,29.413-0.001C43.604,27.387,43.603,14.186,35.496,6.079z M9.905,31.678
                       C3.902,25.675,3.904,15.902,9.907,9.905c6.003-6.002,15.77-6.002,21.771-0.003c5.999,6,5.997,15.762,0,21.774
                       C25.676,37.66,15.91,37.682,9.905,31.678z"/>
                   <path d="M14.18,22.464c-0.441-1.812-2.257-4.326-3.785-3.525c-1.211,0.618-0.87,3.452-0.299,5.128
                       c2.552,7.621,11.833,9.232,12.798,8.268C23.843,31.387,15.928,29.635,14.18,22.464z"/>
               </g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           <g>
           </g>
           </svg>
           </button>
           <button (click)="getFavorites()" type="button" class="btnPerso btnSmall btnPrimary"><span class="fa fa-star favButton"></span></button>
            <label class="dropdown leftButtonGrid" (click)="checkFocus()" (blur)="hidePanel()">
                <button id="dropdownButton" class="dd-button btnPerso btnSmall btnPrimary">Focus sur</button>
                <ul class="dd-menu" id="focusMenu">
                  <li (click)="focusOn('Tout')"><button  class="specialBtn">Tout</button></li>
                  <!-- <li (click)="focusOn('A tagger')" ><button  class="specialBtn">A tagger</button></li> -->
                  <li (click)="focusOn('Recent')"><button  class="specialBtn">Récent</button></li>
                  <li class="divider"></li>
                  <li *ngFor="let label of authorLabels" (click)="focusOn(label)">
                      <button  [innerHTML]="label" class="specialBtn"></button>
                  </li>
                </ul>
            </label>
            <button (click)="aleatoire()" type="button" class="btnPerso btnSmall btnPrimaryLight onlyOnBigScreen">Aléatoire</button>
            <button routerLink="/uploadLivres" type="button" class="rightButton btnPerso btnSmall btnSecondaryLight">Ajouter</button>
        </div>
    </div>
</div>

<div class='stickyHeaderFocus'>
    <div *ngIf="isFocused">
        <div class="headerFocus">
            <div class="grid-item">
                <button (click)="focusOn('Tout')" type="button" class="btnPerso btnGrey btnSmall">←</button>
            </div>
            <div class="grid-item">
                <h4 class="text-center" *ngIf="focusCatalog.length == 0 && !showFavorites">Plus de livre par {{focusedAuthor}}</h4>
                <h4 class="text-center" *ngIf="focusCatalog.length > 1 && !showFavorites">Par {{focusedAuthor}} ({{focusCatalog.length}} 📚)</h4>
                <h4 class="text-center" *ngIf="focusCatalog.length == 1 && !showFavorites">Par {{focusedAuthor}}</h4>
                <h4 class="text-center" *ngIf="showFavorites">Vos favoris ({{focusCatalog.length}})</h4>
            </div>
        </div>
    </div>
</div>
<ng-scrollbar class="my-scrollbar scrollBibli" [scrollAuditTime]="40">
        <!-- <div *ngIf="!condensedView; else elseBlock"> -->
                
        <div *ngIf="!condensedView">
            <div class="booksGrid" *ngIf="auth.user | async as user;">
                <app-book *ngFor="let item of focusCatalog"
                    [bookName]="item.bookName"
                    [bookCover]="item.bookCover"
                    [bookAuthor]="item.bookAuthor"
                    [bookTitle]="item.bookTitle"
                    [downloadURL]="item.downloadURL"
                    [customId]="item.customId"
                    [bookOwner]="item.bookOwner"
                    [bookFormat]="item.format"
                    [favorite]="item.favorite"
                    [userMail]="user.email"
                    style="--aniDelay:{{item.delay}}"
                    class="gridItem">
                </app-book>
            </div>
        </div>

        <!-- <ng-template #elseBlock>
            <div class="row itemsBlock p-3">
                <app-condensed-book *ngFor="let item of focusCatalog"
                    [bookName]="item.bookName"
                    [bookAuthor]="item.bookAuthor"
                    [downloadURL]="item.downloadURL"
                    [customId]="item.customId"
                    [bookFormat]="item.format"
                    class="col-12">
                </app-condensed-book>
            </div>
        </ng-template> -->
    <div class="loadingAnimation">
        <div *ngIf="loading" class="lds-dual-ring"></div>
    </div>
</ng-scrollbar>

