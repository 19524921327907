
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CzechService } from 'src/app/services/czech.service';

@Component({
  selector: 'app-czech-vocab',
  templateUrl: './czech-vocab.component.html',
  styleUrls: ['./czech-vocab.component.scss']
})
export class CzechVocabComponent implements OnInit {
  public categoryList = []
  public displayFrCz = "Fr"
  public titleLanguage = "FR -> CZ"

  constructor(public cz : CzechService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cz.getCategories().toPromise().then(querySnapshot => {
      querySnapshot.forEach((doc) => {
        let newData = doc.data()
        newData['customId'] = doc.id
        newData['titleFR'] = this.toTitleCase(newData['titleFR'])
        newData['titleCZ'] = this.toTitleCase(newData['titleCZ'])
        console.log(newData['titleFR'])
        this.categoryList.push(newData)
      })
      this.ref.detectChanges()
      this.titleToFr()
      sessionStorage.setItem("language", "Fr")
      this.categoryList.sort((a,b)=>(a['titleFR'] > b['titleFR']) ? 1 : ((b['titleFR'] > a['titleFR']) ? -1 : 0))
    })
  }

  switchLanguage(){
    if(this.displayFrCz == "Fr"){
      this.displayFrCz = "Cz"
      this.titleToCz()
      this.titleLanguage = "CZ -> FR"
      sessionStorage.setItem("language", "Cz")
    } else {
      this.displayFrCz = "Fr"
      this.titleToFr()
      this.titleLanguage = "FR -> CZ"
      sessionStorage.setItem("language", "Fr")
    }
    this.ref.detectChanges()
  }

  titleToFr(){
    this.categoryList.forEach(el => {
      el['title'] = this.toTitleCase(el['titleFR'])
    })
  }

  titleToCz(){
    this.categoryList.forEach(el => {
      el['title'] = this.toTitleCase(el['titleCZ'])
    })
  }

  toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }

  getClassCard(){
    if (this.displayFrCz == "Fr"){
      return ({"btnCategory btnPerso btnPrimaryLight" : true})
    } else {
      return ({"btnCategory btnPerso btnSecondaryLight" : true})
    }
  }

}
