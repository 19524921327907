<h2>Synthèse Crypto</h2>

<h4 class="titleCrypto">Value history since 29/01/2019</h4>
<!-- <div id="GraphValueDeposit" #GraphValueDeposit class="graphValue"></div> -->
<plotly-plot class="graphValue" [data]="GraphValueDeposit.data" [layout]="GraphValueDeposit.layout" [config]="GraphValueDeposit.config"></plotly-plot>


<h4 class="titleCrypto">Detailed history per currency</h4>
<!-- <div id="GraphHistogram" #GraphHistogram class="graphHist"></div> -->
<plotly-plot class="graphHist" [data]="GraphHistogram.data" [layout]="GraphHistogram.layout" [config]="GraphHistogram.config"></plotly-plot>
