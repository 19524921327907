import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ChartService } from '../../services/chart.service';
import { FunctionsService } from '../../services/functions.service';
// declare var Plotly: any;
// import * as Plotly from '../../../assets/plotly-latest.min.js';

@Component({
  selector: 'app-chart-ticker',
  templateUrl: './chart-ticker.component.html',
  styleUrls: ['./chart-ticker.component.scss']
})
export class ChartTickerComponent implements OnInit {
  public thresholds : any[];  
  public allData : any[];
  public timeline : any[];
  public dailyTicker : any[];
  public layout = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    }
  }
  public config = {
        responsive: true, 
        scrollZoom: true
  }
  @ViewChild("GraphTicker") graphTicker : ElementRef;

  constructor(private fns : FunctionsService , private chartService : ChartService) { }

  ngOnInit(): void {
    this.fns.updateTickerOnce()
    this.chartService.getDailyTicker()
    .subscribe(data => {
      this.chartService.getThresholds()
      .subscribe(thres => {
        this.topoChart(data, thres)
      })
    })
  }

  topoChart(data, thres) {
    this.thresholds = thres.thresholds
    this.allData = []
    for (let i = 0 ; i < data.length ; i ++){
      this.allData = this.allData.concat(data[i].transactions)
    }
    this.allData = this.allData.sort((a,b) => b.date - a.date)
    this.timeline = this.allData.map(({date}) => (new Date(date.seconds * 1000).toISOString()))
    this.dailyTicker = this.allData.map( ({price})=> price)
    // console.log(this.dailyTicker)
    this.plotDailyTicker()
  }
  
  plotDailyTicker(){
    let t0 = this.fillArray(this.thresholds[0].avg, this.timeline.length)
    let t1 = this.fillArray(this.thresholds[1].avg, this.timeline.length)
    let t2 = this.fillArray(this.thresholds[2].avg, this.timeline.length)
    let t3 = this.fillArray(this.thresholds[3].avg, this.timeline.length)
    let t4 = this.fillArray(this.thresholds[4].avg, this.timeline.length)
    // this.graphTicker = Plotly.newPlot(
    //   this.graphTicker.nativeElement,
    //   [
    //     {x : this.timeline, y : this.dailyTicker, type : 'scatter', name : 'Daily Ticker in €', line : {color : 'rgb(52, 152, 219)'}},
    //   ],
    //   this.layout,
    //   this.config
    // )
  }
  
  fillArray(value, len) {
    var arr = [];
    for (var i = 0; i < len; i++) {
      arr.push(value);
    }
    return arr;
  }

}
