import { ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { CzechService } from 'src/app/services/czech.service';

@Component({
  selector: 'app-vocab-category',
  templateUrl: './vocab-category.component.html',
  styleUrls: ['./vocab-category.component.scss']
})
export class VocabCategoryComponent implements OnInit {
  public customId : number;
  public titleFR : string;
  public titleCZ : string;
  public vocab = []
  eventsSubject: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute, public cz : CzechService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.customId  = this.route.snapshot.params['id'];
    this.cz.getCategoryById(this.customId).subscribe((data : any)=> {
      if(data.hasOwnProperty("titleFR")) {this.titleFR = this.toTitleCase(data.titleFR)}
      if(data.hasOwnProperty("titleCZ")) {this.titleCZ = this.toTitleCase(data.titleCZ)}
      if(data.hasOwnProperty("vocab")) {this.vocab = this.shuffleArray(data.vocab)}
    })
  }

  flipAllCards(){
    this.eventsSubject.next();
    this.ref.detectChanges()
  }

  shuffleVocab(){
    this.vocab = this.shuffleArray(this.vocab)
    this.flipAllCards()
    this.ref.detectChanges()
  }

  shuffleArray(arra1) {
    var ctr = arra1.length, temp, index;
    while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
    }
    return arra1;
  }

  toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }
  

}
