import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FunctionsService } from 'src/app/services/functions.service';
import { PepiteService } from 'src/app/services/pepite.service';


@Component({
  selector: 'app-pepite-creation',
  templateUrl: './pepite-creation.component.html',
  styleUrls: ['./pepite-creation.component.scss']
})
export class PepiteCreationComponent implements OnInit {
  public dataForm = this.formBuilder.group({
    title : '',
    type : '',
    description : '',
    illustration : '',
    location: ''
  })
  public lastRandNum;

  constructor(private pep : PepiteService, private formBuilder: FormBuilder, private func : FunctionsService) { }

  ngOnInit(): void {
    this.pep.getLastRandNum().toPromise().then(querySnapshot => {
      querySnapshot.forEach((doc) => {
        let newData = doc.data()
        this.lastRandNum = newData['randNumber']
        console.log(this.lastRandNum)
      })
    })
  }

  onSubmit(dataPoint){
    let newPepite = {
      title: dataPoint.title,
      description: dataPoint.description,
      illustration: dataPoint.illustration,
      location: dataPoint.location,
      type: dataPoint.type,
      date: new Date(),
      randNumber: this.lastRandNum + 1,
      owner:this.func.getUser().email
    }
    this.lastRandNum ++
    this.pep.createNewPepite(newPepite)
  }
}
