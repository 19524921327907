<div>
    <div class="headerPepite">
        <h2 class="text-center">Découverte de pépites</h2>
        <div class="buttonList">
            <button (click)="aleatoire()" type="button" class="btnPerso btnSmall btnPrimaryLight">Aléatoire</button>
            <button routerLink="/pepiteCreation" type="button" class="rightButton btnPerso btnSmall btnSecondaryLight">Ajouter</button>
        </div>
    </div>
    <h5>Les belles choses son trop rares pour ne pas êtres partagés. Ajoutez ici ce qui vous a fait rire, pleurer, chanter, vous régaler et nourissons-nous les uns des autres !</h5>
</div>

<div class="pepitesGrid">
    <app-pepite *ngFor="let item of pepiteCatalog"
        [date]="item.date"
        [description]="item.description"
        [illustration]="item.illustration"
        [location]="item.location"
        [owner]="item.owner"
        [randNumber]="item.randNumber"
        [title]="item.title"
        [customId]="item.customId"
        [type]="item.type">
    </app-pepite>
</div>