import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class WebScrapperService {
  constructor(private firestore: AngularFirestore) { }

  getTopMatches(){
    return this.firestore.doc("rugbyGames/top14").valueChanges()
  }

  getChampionsMatches(){
    return this.firestore.doc("rugbyGames/championsCup").valueChanges()
  }
  
  getNationsMatches(){
    return this.firestore.doc("rugbyGames/sixNations").valueChanges()
  }
}
