
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as Loan from 'loan'
import { ChartService } from '../../services/chart.service';

@Component({
  selector: 'app-loan-simulator',
  templateUrl: './loan-simulator.component.html',
  styleUrls: ['./loan-simulator.component.scss']
})
export class LoanSimulatorComponent implements OnInit {
  public loanForm;
  public showAlert = false;
  public showResult = false;
  public simulation;
  public monthly_fee = 0
  public showApp = false
  public layout = {
    margin: {
      l: 0,
      r: 5,
      b: 0,
      t: 0,
    },
    height : 230,
    width : window.innerWidth < 700 ? window.innerWidth *0.95 : window.innerWidth *0.7,
    showlegend: false,
    barmode: 'stack',
  }

  public config = {
    responsive: true, 
    scrollZoom: true
  }

  public GraphPlan = {
    data:[],
    layout:this.layout,
    config:this.config,
  }
  
  public apportForm = {
    compte_courant : {
      lastValue : 0,
      id : "Compte courant",
      checked : false,
    },
    compte_courant_cz : {
      lastValue : 0,
      id : "Compte courant CZ",
      checked : false,
    },
    ldd : {
      lastValue : 0,
      id : "Livret A",
      checked : false,
    },
    ldd_lucie : {
      lastValue : 0,
      id : "Livret A Lucie",
      checked : false,
    },
    pel : {
      lastValue : 0,
      id : "PEL",
      checked : false,
    },
    pel_lucie : {
      lastValue : 0,
      id : "PEL Lucie",
      checked : false,
    },
    av_ca : {
      lastValue : 0,
      id : "Assurance vie CA",
      checked : false,
    },
    generali_actif_general : {
      lastValue : 0,
      id : "Generali Actif General",
      checked : false,
    },
    generali_sci : {
      lastValue : 0,
      id : "Generali SCI",
      checked : false,
    },
    generali_opci : {
      lastValue : 0,
      id : "Generali OPCI",
      checked : false,
    },
    av_lucie : {
      lastValue : 0,
      id : "Assurance vie Lucie",
      checked : false,
    },
    csob_bohatstvi : {
      lastValue : 0,
      id : "CSOB Bohatstvi",
      checked : false,
    },
    csob_velmi_opatrny : {
      lastValue : 0,
      id : "CSOB Bohatstvi",
      checked : false,
    },
    term_deposit : {
      lastValue : 0,
      id : "Term deposit",
      checked : false,
    },
    october : {
      lastValue : 0,
      id : "October",
      checked : false,
    },
    action_ws : {
      lastValue : 0,
      id : "Action Wavestone",
      checked : false,
    },
    action_sap : {
      lastValue : 0,
      id : "Actions SAP",
      checked : false,
    },
    action_revolut : {
      lastValue : 0,
      id : "Actions Revolut",
      checked : false,
    },
    advize : {
      lastValue : 0,
      id : "Advize",
      checked : false,
    },
    crypto : {
      lastValue : 0,
      id : "Cryptocurrency",
      checked : false,
    },
    papa_maman : {
      lastValue : 25000,
      id : "Don parental",
      checked : false,
    },
    appart_otroko : {
      lastValue : 0,
      id : "Appart Otrokovice",
      checked : false,
    },
    total_savings : {
      lastValue : 0,
      id : "Total",
      checked : false,
    },
  }

  public apportList;
  public displayPlan = false;
  public tauxdendettement = 0;

  constructor(private formBuilder: FormBuilder, private chartService : ChartService, private ref: ChangeDetectorRef) {
    this.loanForm = this.formBuilder.group({
      apport : 0,
      interest_rate: 1,
      total_amount: 0,
      duration : 10,
      salaire_actuel :3000
    });
  }

  ngOnInit(): void {
    this.chartService.getEpargneHistory()
      .subscribe(data => {
        this.fillApportForm(data)
        this.apportList = this.getApportList()
      })
  }

  fillApportForm(data){
    this.apportForm.compte_courant.lastValue = this.last(data.map( ({compte_courant})=> compte_courant))
    this.apportForm.compte_courant_cz.lastValue = this.last(data.map( ({compte_courant_cz})=> compte_courant_cz))
    this.apportForm.ldd.lastValue = this.last(data.map( ({ldd})=> ldd))
    this.apportForm.ldd_lucie.lastValue = this.last(data.map( ({ldd_lucie})=> ldd_lucie))
    this.apportForm.pel.lastValue = this.last(data.map( ({pel})=> pel))
    this.apportForm.pel_lucie.lastValue = this.last(data.map( ({pel_lucie})=> pel_lucie))
    this.apportForm.av_ca.lastValue = this.last(data.map( ({av_ca})=> av_ca))
    this.apportForm.generali_actif_general.lastValue = this.last(data.map( ({generali_actif_general})=> generali_actif_general))
    this.apportForm.generali_sci.lastValue = this.last(data.map( ({generali_sci})=> generali_sci))
    this.apportForm.generali_opci.lastValue = this.last(data.map( ({generali_opci})=> generali_opci))
    this.apportForm.av_lucie.lastValue = this.last(data.map( ({av_lucie})=> av_lucie))
    this.apportForm.october.lastValue = this.last(data.map( ({october})=> october))
    this.apportForm.action_ws.lastValue = this.last(data.map( ({action_ws})=> action_ws))
    this.apportForm.action_sap.lastValue = this.last(data.map( ({action_sap})=> action_sap))
    this.apportForm.action_revolut.lastValue = this.last(data.map( ({action_revolut})=> action_revolut))
    this.apportForm.csob_bohatstvi.lastValue = this.last(data.map( ({csob_bohatstvi})=> csob_bohatstvi))
    this.apportForm.csob_velmi_opatrny.lastValue = this.last(data.map( ({csob_velmi_opatrny})=> csob_velmi_opatrny))
    this.apportForm.term_deposit.lastValue = this.last(data.map( ({term_deposit})=> term_deposit))
    this.apportForm.advize.lastValue = this.last(data.map( ({advize})=> advize))
    this.apportForm.crypto.lastValue = this.last(data.map( ({crypto})=> crypto))
    this.apportForm.appart_otroko.lastValue = this.last(data.map( ({appart_otroko})=> appart_otroko))
  }

  onSubmit(dataPoint) {
    if(dataPoint.total_amount == 0 || dataPoint.interest_rate == 0 || dataPoint.duration == 0){
      this.showAlert = true
      this.ref.detectChanges()
    } else {
      this.showAlert = false
      this.simulation = new Loan({
        pay_every: 'month',
        principal: dataPoint.total_amount - dataPoint.apport,
        interest_rate: dataPoint.interest_rate/100,
        instalments: dataPoint.duration * 12
      })
      let paymentPlan = this.simulation.getPaymentPlan()
      this.monthly_fee = paymentPlan[0].to_pay
      this.tauxdendettement = +((this.monthly_fee/dataPoint.salaire_actuel)*100).toFixed(1)
      this.showResult = true
      this.displayPlan = true
      this.plotPlan(paymentPlan)
      this.ref.detectChanges()
    }
  }

  plotPlan(paymentPlan){
    let timeline = paymentPlan.map( ({as_of})=> as_of)
    let capital = paymentPlan.map( ({amortization})=> amortization.toFixed(2))
    let interests = paymentPlan.map( ({interest})=> interest.toFixed(2))
    this.GraphPlan.data = [
          {
            x : timeline,
            y : capital,
            type : 'bar',
            name : "Capital",
            marker : {color : "#4f657d"}
          },
          {
            x : timeline,
            y : interests,
            type : 'bar',
            name : "Intérêts",
            marker : {color : "#f75840"}
          }
        ]
    this.ref.detectChanges()
  }

  editApportForm(){
    let result = 0
    this.apportList.forEach(el => {
      if(el.checked){
        result += el.lastValue
      }
    })
    this.loanForm.controls['apport'].setValue(result)
  }

  showApport(){
    this.showApp = !this.showApp
  }

  getApportList(){
    let result = []
    Object.keys(this.apportForm).forEach(key =>{
      if(key !== "total_savings")
      result.push(this.apportForm[key])
    })
    return result
  }

  last(array) {
    return array[array.length - 1];
  }

  getStylePlan() {
    if(!this.displayPlan){
      return {'display': 'none'}
    } else {
      return ''
    }
  }

  getStyleTaux() {
    if(this.tauxdendettement > 33){
      return {'color': 'red'}
    } else {
      return {'color': 'green'}
    }
  }

  onResize(event) {
    let newWidth = event.target.innerWidth;
    let update = {
      width: newWidth < 700 ? newWidth *0.95 : newWidth *0.7,  // or any new width
      height: 230  // " "
    };
    // Plotly.relayout(this.elGraphPlan.nativeElement, update);
  }

}
