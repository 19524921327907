import { Component, NgModule } from '@angular/core';
import { FunctionsService } from './services/functions.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  // public isCollapsed = true;


  constructor(public fnc : FunctionsService) {
    fnc.loggedIn = !!sessionStorage.getItem('user')
  }

}