
<div class="epargneHeader">
    <h2>Synthèse financière</h2>
    <div class="btnList">
        <label class="dropdown leftButtonGrid" (click)="checkFocus()" (blur)="hidePanel()">
            <button id="dropdownButton" class="dd-button btnPerso btnSmall btnPrimary">Changer de vue</button>
            <ul class="dd-menu" id="focusMenu">
                <li (click)="focusOn('Totale')"><button  class="specialBtn">Vue totale</button></li>
                <li (click)="focusOn('Risque')" ><button  class="specialBtn">Vue risque</button></li>
                <li class="divider"></li>
                <li *ngFor="let label of allLabels" (click)="focusOn(label)">
                    <button  [innerHTML]="label" class="specialBtn"></button>
                </li>
            </ul>
        </label>
        <button routerLink="../loan" type="button" class="btnPerso btnSmall btnPrimaryLight">Simuler un prêt</button>
        <button routerLink="../createdata" type="button" class="btnPerso btnSmall btnSecondary">Nouvelle donnée</button>
        <button routerLink="../moneyDeni" type="button" class="btnPerso btnSmall btnSecondaryLight">Dee</button>
    </div>
</div>

<div class="lowerZ">
    <div class="histAndCam" [ngClass]="getClassCam()">
        <div class="hist chatWithTitle">
            <h4>{{titre}}</h4>
            <plotly-plot class="histChart" [data]="GraphEpargneHistory.data" [layout]="GraphEpargneHistory.layout" [config]="GraphEpargneHistory.config"></plotly-plot>
            <!-- <div id="GraphEpargneHistory" class="histChart" #GraphEpargneHistory></div> -->
        </div>
        <div class="cam chatWithTitle" *ngIf="displayCamembert">
            <h4>{{titreCamembert}}</h4>
            <!-- <div id="GraphCamembert" class="camChart" #GraphCamembert></div> -->
            <plotly-plot class="GraphCamembert" [data]="GraphCamembert.data" [layout]="GraphCamembert.layout" [config]="GraphCamembert.config"></plotly-plot>
            <!-- <div class="camChart"></div> -->
        </div>
    </div>

    <div class="chatWithTitle" *ngIf="!(this.displayingRisk)">
        <h4>{{titreGrowth}}</h4>
        <plotly-plot class="GraphGrowth" [data]="GraphGrowth.data" [layout]="GraphGrowth.layout" [config]="GraphGrowth.config"></plotly-plot>
        <!-- <div id="GraphGrowth" class="growthChart" #GraphGrowth></div> -->
    </div>

    <div class="bestAndWorst" *ngIf="!(this.displayingRisk || this.isFocused)">
        <h4>Bests and worsts of the month</h4>
        <div class="bestAndWorstTable">
            <div class="bestTable">
                <svg class="trendSVG" id="Capa_1" enable-background="new 0 0 512 512" height="100%" viewBox="0 0 512 512" width="100%" xmlns="http://www.w3.org/2000/svg"><g><path d="m512 482h-30v-302h-91v302h-30v-182h-90v182h-30v-242h-90v242h-30v-152h-91v152h-30v30h512z"/><path d="m512 120v-120h-121v30h69.789l-144.789 143.789-120-120-191.605 190.606 21.21 21.21 170.395-169.394 120 120 166-165v68.789z"/></g></svg>
                <div class="badges">
                    <span class="badge positive" *ngFor="let item of upGrowthArray">{{item.label}} +{{item.growth}} €</span> <br>
                </div>
            </div>
            <div class="worstTable">
                <svg class="trendSVG" id="Capa_1" enable-background="new 0 0 512 512" height="100%" viewBox="0 0 512 512" width="100%" xmlns="http://www.w3.org/2000/svg"><g><path d="m482 330h-91v152h-30v-242h-90v242h-30v-182h-90v182h-30v-302h-91v302h-30v30h511l1-30h-30z"/><path d="m482 218.789-166-165-120 120-174.789-173.789-21.211 21.211 196 195 120-120 144.789 143.789h-69.789v30h121v-120h-30z"/></g></svg>
                <div class="badges">
                    <span class="badge negative" *ngFor="let item of downGrowthArray">{{item.label}} {{item.growth}} €</span>
                </div>
            </div>
        </div>
    </div>

    <div class="chatWithTitle" *ngIf="!(this.displayingRisk)">
        <h4>{{titreMonthGrowth}}</h4>
        <plotly-plot class="GraphMonthGrowth" [data]="GraphMonthGrowth.data" [layout]="GraphMonthGrowth.layout" [config]="GraphMonthGrowth.config"></plotly-plot>
        <!-- <div id="GraphMonthGrowth" class="monthGrowthChart" #GraphMonthGrowth></div> -->
    </div>

    <div class="avgMonthlyGrowth" *ngIf="!(this.displayingRisk)">
        <h4>Average monthly growth</h4>
        <span class="badge positive" *ngFor="let item of getYearGrowth(); let i = index">{{item.year}} : {{item.avg}} €</span> <br>
    </div>
</div>