<div class="dropzone" 
    dropzone
    (hovered)="toggleHover($event)"
    (dropped)="onDrop($event)"
    [class.hovering]="isHovering">

    <h2>Tu peux mettre de nouveaux livres ici</h2>
    <p class="normalP">Il suffit de faire un petit drag & drop :)</p>
</div>

<div class="btnBottomRight">
    <input (change)="handleFileInput($event.target.files)" type="file" style="display: none" #file />
    <button type="button" class="btnPerso btnPrimary" (click)="file.click()">... ou via ce bouton</button>
</div>

<h3 *ngIf="files.length > 0">Téléchargements ...</h3>

<div *ngFor="let file of files">
    <upload-task [file]="file"></upload-task>
</div>

<div class="btnBottom">
    <button type="button" class="btnPerso btnGrey" routerLink="../bibliotheque">Retour à la bibliothèque</button>
</div>