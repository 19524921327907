<h2>Modifier les informations sur la pépite</h2>
<div class="mainContent">
    <form [formGroup]="dataForm" (ngSubmit)="onSubmit(dataForm.value)">
        <fieldset class="mainForm">
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Type</span>
            </div>
            <input id="type" name="type" type="text" formControlName="type" class="userInput">

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Titre</span>
            </div>
            <input id="title" name="title" type="text" formControlName="title" class="userInput">

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Description</span>
            </div>
            <textarea rows = "5" id="description" type="text" formControlName="description" class="userInput"></textarea>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Illustration</span>
            </div>
            <input id="illustration" name="illustration" type="text" placeholder="URL de l'image à afficher" formControlName="illustration" class="userInput">

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Localisation</span>
            </div>
            <input id="location" name="location" placeholder="URL google map (facultatif)" type="text" formControlName="location" class="userInput">


        </fieldset>

        <div class="btnList">
            <button type="submit" class="btnPerso btnSecondary">Enregistrer</button>
            <button type="reset" routerLink="/pepiteHome" class="btnPerso btnGreyLight">Annuler</button>
        </div>
        
    </form>
</div>