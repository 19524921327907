import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PepiteService {

  firstPageSize = 10

  constructor(private fire : AngularFirestore, private ngZone:NgZone, private router : Router) { }

  getInitBooks(){
    return this.fire.collection("pepites", ref => ref
        .limit(this.firstPageSize)
      ).get()
  }

  getLastRandNum(){
    return this.fire.collection('pepites', ref => ref
      .orderBy("randNumber", 'desc')
      .limit(1)
      ).get()
  }

  createNewPepite(dict){
    this.fire.collection('pepites').add(dict).then(() => {
      setTimeout( () => {
        this.ngZone.run(()=>this.router.navigate(['/pepiteHome']))
      }, 500 );
    })
  }

  getPepitebyId(id){
    return this.fire.doc("pepites/" + id).valueChanges()
  }

  async updatePepiteData(id, dict){
    return this.fire.doc("pepites/" + id).set(dict, {merge : true})
  }
}
