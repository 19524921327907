import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColorPreferenceService {
    public colorMode = new Subject<string>()
    public realColorMode;

  constructor() {
    this.colorMode.subscribe(data => this.realColorMode = data)
  }

  checkInitialColorMode(){
    let storedTheme = localStorage.getItem('theme')
    let prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    if (storedTheme === 'dark'){
      this.switchToDark()
    } else if (storedTheme == null && prefersDarkScheme){
      this.switchToDark()
    } else {
      this.switchToLight()
    }
  }

  switchTheme(){
    if(this.realColorMode === 'dark'){
      this.switchToLight()
      localStorage.setItem("theme", "light")
    } else {
      this.switchToDark()
      localStorage.setItem("theme", "dark")
    }
  }

  switchToDark(){
    let mainHTML = document.getElementById("mainHTML")
    mainHTML.className = 'dark-mode';
    this.colorMode.next('dark')
  }

  switchToLight(){
    let mainHTML = document.getElementById("mainHTML")
    mainHTML.className = 'light-mode';
    this.colorMode.next('light')
  }
}
