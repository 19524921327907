import { Component, OnInit , Input} from '@angular/core';
import { BibliService } from 'src/app/services/bibli.service';

@Component({
  selector: 'app-condensed-book',
  templateUrl: './condensed-book.component.html',
  styleUrls: ['./condensed-book.component.scss']
})
export class CondensedBookComponent implements OnInit {
  @Input() customId : number;
  @Input() bookName : string;
  @Input() bookAuthor : string;
  @Input() downloadURL : string;
  @Input() bookFormat : string;

  constructor(private bibli : BibliService) { }

  ngOnInit(): void {
  }

  downloadBook(){
    window.open(this.downloadURL)
    this.bibli.logDownload(this.bookName)
  }

}
