import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-vocab-word',
  templateUrl: './vocab-word.component.html',
  styleUrls: ['./vocab-word.component.scss']
})
export class VocabWordComponent implements OnInit {
  @Input() fr : string;
  @Input() cz : string;
  @Input() events: Observable<void>;
  public language = "Fr"
  public title : string;
  public initialLanguage : string;
  private eventsSubscription: Subscription;

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initialLanguage = sessionStorage.getItem("language")
    this.language = this.initialLanguage
    this.language == "Fr" ? this.title = this.toTitleCase(this.fr) : this.title = this.toTitleCase(this.cz)
    this.eventsSubscription = this.events.subscribe(() => this.flipBack());
  }

  flip(){
    if(this.language == "Fr"){
      this.title = this.toTitleCase(this.cz)
      this.language = "Cz"
    } else {
      this.title = this.toTitleCase(this.fr)
      this.language = "Fr"
    }
    this.ref.detectChanges()
  }

  flipBack(){
    this.language = this.initialLanguage
    this.language == "Fr" ? this.title = this.toTitleCase(this.fr) : this.title = this.toTitleCase(this.cz)
    this.ref.detectChanges()
  }

  getClassCard(){
    if (this.language == "Fr"){
      return ({"btnWord btnPerso btnPrimaryLight" : true})
    } else {
      return ({"btnWord btnPerso btnSecondaryLight" : true})
    }
  }

  toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }

}
