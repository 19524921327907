import { Component, OnInit , Input} from '@angular/core';
import { Router } from '@angular/router';
import { BibliService } from 'src/app/services/bibli.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  @Input() customId : number;
  @Input() bookName : string;
  @Input() bookCover : string;
  @Input() bookAuthor : string;
  @Input() bookTitle : string;
  @Input() downloadURL : string;
  @Input() bookOwner : string;
  @Input() bookFormat : string;
  @Input() userMail:string;
  @Input() favorite:boolean;
  mailSent=false;

  constructor( private bibli : BibliService, private router : Router) { }

  ngOnInit(): void {
  }

  mailRequested(){
    this.bibli.mailRequested(this.mailSent, this.bookTitle, this.bookAuthor, this.bookFormat, this.downloadURL, (result) => {
      console.log(result)
      if(result == "ok"){
        this.mailSent=true;
      }
    })
  }
  
  downloadBook(){
    window.open(this.downloadURL)
    this.bibli.logDownload(this.bookTitle)
  }

  getNameClassName(){
    if (this.bookName.length > 25 && this.bookName.length <= 30 ){
      return ({'smallerName' : true})
    } else if (this.bookName.length > 30){
      return ({'muchSmallerName' : true})
    }
  }

  getNameClassTitle(){
    if (this.bookTitle.length > 25 && this.bookTitle.length <= 35 ){
      return ({'smallerTitle' : true})
    } else if (this.bookName.length > 40){
      return ({'muchSmallerTitle' : true})
    }
  }

  toggle(e){
    const excludedTagNames = ["BUTTON", "svg", "path"];
    console.log(e.target.tagName)
    if(!excludedTagNames.includes(e.target.tagName)){
      this.router.navigate(['/bibliotheque/', this.customId])
    }
  }

  getClassFavorite(){
    if (this.favorite){
      return ({"fa fa-star favorite checked" : true})
    } else{
      return ({"fa fa-star favorite" : true})
    }
  }

  favoriteAction(){
    this.favorite?this.bibli.removeFromFavorite(this.customId, this.userMail):this.bibli.addToFavorite(this.customId, this.userMail)
    this.favorite = !this.favorite;
  }

}
