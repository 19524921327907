import { Component, OnInit } from '@angular/core';
import { PepiteService } from 'src/app/services/pepite.service';

@Component({
  selector: 'app-pepite-home',
  templateUrl: './pepite-home.component.html',
  styleUrls: ['./pepite-home.component.scss']
})
export class PepiteHomeComponent implements OnInit {
  pepiteCatalog = []

  constructor(private pep : PepiteService) { }

  ngOnInit(): void {
    let initPep = this.pep.getInitBooks().toPromise().then(querySnapshot => {
      querySnapshot.forEach((doc) => {
        let newData = doc.data()
        newData['customId'] = doc.id
        this.pepiteCatalog.push(newData)
      })
      this.pepiteCatalog = this.shuffleArray(this.pepiteCatalog)
    })
  }

  aleatoire() {
    console.log('aleatoire')
  }

  shuffleArray(arra1) {
    var ctr = arra1.length, temp, index;
    while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
    }
    return arra1;
  }

}
