import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { arrayUnion, increment } from '@angular/fire/firestore'
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
// import {parser} from 'simple-epub-parser';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {
  public increment = increment(1)

  @Input() file: File;

  task: AngularFireUploadTask;

  percentage: any;
  snapshot: any;
  downloadURL: string;

  constructor(private ref: ChangeDetectorRef, private fns: AngularFireFunctions, private storage: AngularFireStorage, private db: AngularFirestore, private auth : AngularFireAuth) { }

  ngOnInit() {
    // var reader = new FileReader();

    // parser(Buffer.from(this.file))
    // .then(epub => {
    //       console.log(epub.info)
    //   })
    
    // reader.onload = function() {
    //   console.log(reader.result)
    //   parseEpub(reader.result, {type: 'binaryString'})
    //   .then(epub => {
    //         console.log(epub.info)
    //       })
    // };
    // // }
    // reader.onerror = function(e) {
    //   console.log('Error : ' + e.type);
    // };
    // reader.readAsText(this.file);
    this.startUpload();
    this.ref.detectChanges()
  }

  startUpload() {

    // The storage path
    const path = `bibliotheque/${Date.now()}_${this.file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);
    this.ref.detectChanges()

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    const obs = this.task.snapshotChanges() as unknown as Observable<UploadTaskSnapshot>

    this.snapshot  = obs.pipe(
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        let currentUser = (await this.auth.currentUser)
        let displayName = currentUser.displayName
        let userEmail = currentUser.email
        this.db.collection('bibliotheque').add( {
          downloadURL: this.downloadURL,
          path : path,
          bookName : this.file.name,
          bookCover : "https://i.pinimg.com/736x/c0/28/54/c02854a59bb95f10e076485898a1841a.jpg",
          date: new Date(),
          format : this.file.type,
          bookOwner : displayName,
          bookAuthor : "Inconnu",
          bookTitle : "Inconnu"
        });  
      this.db.doc("general_data/bibli_data").update({nbBooks : this.increment}).then(() =>{
        }).catch(err => console.log(err))
        this.db.doc("bibliotheque_history/" + userEmail).set({
            history : arrayUnion({
              type : "Upload",
              date : new Date(),
              bookTitle : this.file.name 
            })
          }, 
          {merge: true}
          ).then(() =>{
          const callable = this.fns.httpsCallable('bookInfo');
          let paramMail = {
            message : `${this.file.name} a été ajouté par ${displayName}`
          }
          callable(paramMail).subscribe(data => {
          })
        }).catch(err => console.log(err))
      }),
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }


}