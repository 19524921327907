<p class="normalP">{{file.name}}</p>
<div *ngIf="percentage | async as pct">
    <progress [value]="pct" max="100"></progress>
    {{ pct | number : '1.0-0' }}%
  </div>
  


<div *ngIf="snapshot | async as snap" class="buttonList">

  <!-- {{ snap.bytesTransferred }} of {{ snap.totalBytes }}  -->

  <button type="button" class="btnPerso btnGreyLight btnSmall" (click)="task.pause()" [disabled]="!isActive(snap)">Pause</button>
  <button type="button" class="btnPerso btnSecondary btnSmall" (click)="task.cancel()" [disabled]="!isActive(snap)">Cancel</button>
  <button type="button" class="btnPerso btnPrimary btnSmall" (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">Resume</button>
</div>