import { ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ChartService } from '../../services/chart.service';
// declare var Plotly: any;
// import * as Plotly from '../../../assets/plotly-latest.min.js';


@Component({
  selector: 'app-chart-history',
  templateUrl: './chart-history.component.html',
  styleUrls: ['./chart-history.component.scss']
})
export class ChartHistoryComponent implements OnInit {
  public timeline : any[];
  public histValue : any[];
  public histDeposit : any[];
  public layoutValueDeposit = {
    // width: window.innerWidth * 1,
    // height: window.innerHeight * 0.4,
    // title: 'Historical data since 28/01/2019',
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
    height: 400,
    autosize:true,
    showlegend: true,
    legend: {
      x: 0.2,
      y: -0.2,
      xanchor: 'right',
      yanchor: 'bottom',
      orientation : 'h',
    }
  }
  public layoutHistogram = {
    // width: window.innerWidth * 1,
    // height: window.innerHeight * 0.4,
    // title: 'Detailed historical data',
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
    height: 400,
    autosize:true,
    showlegend: true,
    legend: {
      x: 0.37,
      y: -0.1,
      xanchor: 'right',
      yanchor: 'top',
      orientation : 'h',
    }
  }
  public config = {
        responsive: true, 
        }
  public btcValue : any[];
  public xrpValue : any[];
  public ethValue : any[];
  public eth2Value : any[];
  public sgbValue : any[];
  public euroTotal : any[];

  public GraphValueDeposit = {
    data:[],
    layout:this.layoutValueDeposit,
    config:this.config,
  }

  public GraphHistogram = {
    data:[],
    layout:this.layoutHistogram,
    config:this.config,
  }

  constructor(private chartService : ChartService, private ref: ChangeDetectorRef) {
    // this.getScreenSize();
  }

  ngOnInit(): void {
    this.chartService.getHistoryData("portfolio_history")
      .subscribe(data => {
        this.topoChart(data)
      })
  }

  topoChart(data) {
    this.timeline = data.map(({date}) => (new Date(date.seconds * 1000).toISOString()));
    this.histValue = data.map( ({total_value})=> total_value.toFixed(2))
    this.histDeposit = data.map( ({deposit_total})=> deposit_total.toFixed(2))
    this.btcValue = data.map(( {btc_value}) => btc_value.toFixed(2))
    this.xrpValue = data.map(( {xrp_value}) => xrp_value.toFixed(2))
    this.ethValue = data.map(( {eth_value}) => eth_value ? eth_value.toFixed(2) : 0)
    this.eth2Value = data.map(( {eth2_value}) => eth2_value ? eth2_value.toFixed(2) : 0)
    this.sgbValue = data.map(( {sgb_value}) => sgb_value ? sgb_value.toFixed(2) : 0)
    this.euroTotal = data.map(( {eur_total}) => eur_total.toFixed(2))
    this.plotValueDepositGraph()
    this.ref.detectChanges()
    this.plotHistogramGraph()
    this.ref.detectChanges()
  }
  
  plotValueDepositGraph(){
    this.GraphValueDeposit.data = [
          {x : this.timeline, y : this.histValue, type : 'scatter', name : 'Value in €', line : {color : 'rgb(59, 76, 94)'}},
          {x : this.timeline, y : this.histDeposit, type : 'scatter', name : 'Deposit in €', line : {color : 'rgb(247, 88, 64)'}}
        ]
    // this.elValueDeposit = Plotly.newPlot(
    //   this.elValueDeposit.nativeElement,
    //   [
    //     {x : this.timeline, y : this.histValue, type : 'scatter', name : 'Value in €', line : {color : 'rgb(59, 76, 94)'}},
    //     {x : this.timeline, y : this.histDeposit, type : 'scatter', name : 'Deposit in €', line : {color : 'rgb(247, 88, 64)'}}
    //   ],
    //   this.layoutValueDeposit,
    //   this.config
    // )
  }

  plotHistogramGraph(){
    this.GraphHistogram.data = [
        {x : this.timeline, y : this.xrpValue, stackgroup: 'one', name : '% XRP', fillcolor: 'hsl(211, 79%, 62%)', line : {color : 'hsl(211, 79%, 53%)'}, groupnorm : "percent"},
        {x : this.timeline, y : this.sgbValue, stackgroup: 'one', name : '% SGB', fillcolor: 'hsl(155, 87%, 50%)', line : {color : 'hsl(155, 87%, 40%)'}, groupnorm : "percent"},
        {x : this.timeline, y : this.ethValue, stackgroup: 'one', name : '% ETH', fillcolor: 'hsl(68, 85%, 58%)', line : {color : 'hsl(68, 85%, 49%)'}, groupnorm : "percent"},
        {x : this.timeline, y : this.eth2Value, stackgroup: 'one', name : '% ETH2', fillcolor: 'hsl(85, 85%, 58%)', line : {color : 'hsl(85, 85%, 49%)'}, groupnorm : "percent"},
        {x : this.timeline, y : this.btcValue, stackgroup: 'one', name : '% BTC', fillcolor: 'hsl(8, 92%, 70%)', line : {color : 'hsl(8, 92%, 61%)'}, groupnorm : "percent"},
        {x : this.timeline, y : this.euroTotal, stackgroup: 'one', name : '% €', fillcolor: 'hsl(0, 7%, 95%)', line : {color : 'hsl(0, 7%, 90%)'}, groupnorm : "percent"}
      ]
    // this.elHistogram = Plotly.newPlot(
    //   this.elHistogram.nativeElement,
    //   [
    //     {x : this.timeline, y : this.xrpValue, stackgroup: 'one', name : '% XRP', fillcolor: 'hsl(211, 79%, 62%)', line : {color : 'hsl(211, 79%, 53%)'}, groupnorm : "percent"},
    //     {x : this.timeline, y : this.sgbValue, stackgroup: 'one', name : '% SGB', fillcolor: 'hsl(155, 87%, 50%)', line : {color : 'hsl(155, 87%, 40%)'}, groupnorm : "percent"},
    //     {x : this.timeline, y : this.ethValue, stackgroup: 'one', name : '% ETH', fillcolor: 'hsl(68, 85%, 58%)', line : {color : 'hsl(68, 85%, 49%)'}, groupnorm : "percent"},
    //     {x : this.timeline, y : this.btcValue, stackgroup: 'one', name : '% BTC', fillcolor: 'hsl(8, 92%, 70%)', line : {color : 'hsl(8, 92%, 61%)'}, groupnorm : "percent"},
    //     {x : this.timeline, y : this.euroTotal, stackgroup: 'one', name : '% €', fillcolor: 'hsl(0, 7%, 95%)', line : {color : 'hsl(0, 7%, 90%)'}, groupnorm : "percent"}
    //   ],
    //   this.layoutHistogram,
    //   this.config
    // )
  }

}
