<span [ngClass]='getClassFavorite()' (click)="favoriteAction()"></span>
<div class="bookGrid" (click)='toggle($event)'>
    <div class="bookCover">
        <img src="{{bookCover}}" class="coverImg" onerror="this.onerror=null;this.src='https://i.pinimg.com/736x/c0/28/54/c02854a59bb95f10e076485898a1841a.jpg';">
    </div>
    <div class="bookInfo">
        <div class="bookInfoDetails">
            <h5 class="bookName" [ngClass]='getNameClassName()' >{{ bookName }}</h5>
            <p *ngIf="bookTitle !== bookName" class="bookTitle centerP smallP" [ngClass]='getNameClassTitle()' >{{bookTitle}}</p>
        </div>
        <p class="bookAuthor boldP centerP" >{{bookAuthor}}</p>
            <!-- <p class="bookOwner">Ajouté par {{bookOwner}}</p> -->
        <div class="bookActions">
            <p class="bookFormat smallP" >{{bookFormat}}</p>
            <button type="button" class="actionBtn btnPerso btnSmall btnPrimary" (click)="downloadBook()" data-toggle="tooltip" data-placement="top" title="Télécharger">
                <svg class="downloadIcon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
    <g>
        <path d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
            c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
            c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"/>
    </g>
</g>
<g>
    <g>
        <path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z"/>
    </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
                </svg>
            </button>
            <button type="button" id="mailButton" class="actionBtn actionBtnLight btnPerso btnSmall btnSecondaryLight" (click)="mailRequested()">
                <svg class="mailIcon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
<g>
    <g>
        <path d="M460.8,68.267H17.067l221.867,182.75L463.309,68.779C462.488,68.539,461.649,68.368,460.8,68.267z"/>
    </g>
</g>
<g>
    <g>
        <path d="M249.702,286.31c-6.288,5.149-15.335,5.149-21.623,0L0,98.406v294.127c0,9.426,7.641,17.067,17.067,17.067H460.8
            c9.426,0,17.067-7.641,17.067-17.067V100.932L249.702,286.31z"/>
    </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
                </svg>
            </button>
            <!-- <button type="button" class="btnPerso btnSmall btnPrimaryLight" (click)="sendByMail()">📧</button> -->
            <!-- <button type="button" class="btn btn-light btn-sm me-1" (click)="open(content)" data-toggle="tooltip" data-placement="top" title="Recevoir le lien par mail">📧</button> -->
            <button [routerLink]="['/editLivre/', customId]" type="button" class="actionBtn actionBtnLight btnPerso btnSmall btnPrimaryLight" data-toggle="tooltip" data-placement="top" title="Modifier">
                <svg class="editIcon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="528.899px" height="528.899px" viewBox="0 0 528.899 528.899" style="enable-background:new 0 0 528.899 528.899;"
    xml:space="preserve">
<g>
    <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
        c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
        C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
        L27.473,390.597L0.3,512.69z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
                </svg>
            </button>
        </div>
    </div>
</div>