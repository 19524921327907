import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  constructor(private ref: ChangeDetectorRef){}

  ngOnInit(): void {
    this.ref.detectChanges()
  }
  
  fileToUpload: File = null;

  isHovering: boolean;

  files: File[] = [];

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    this.ref.detectChanges()
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
    this.ref.detectChanges()
  }

  handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }

}