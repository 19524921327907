import { Component, OnInit } from '@angular/core';
import { ChartService } from '../../services/chart.service';
import { FunctionsService } from '../../services/functions.service';

@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss']
})
export class TickerComponent implements OnInit {
  public transactionItems : any[]
  public openOrdersItems : any[]
  public balance : any

  constructor(private fns : FunctionsService, private chartService : ChartService) {}

  ngOnInit(): void {
    this.fns.updateTransationHistoryOnce()
    this.fns.updateOpenOrdersOnce()
    this.fns.updateBalanceOnce()
    this.chartService.getTransactionHistory()
      .subscribe((data : any)=> {
        this.transactionItems = data.transactions.filter(a => Math.abs(a.eur) > 1).sort((a,b) => b.date - a.date)
      })
    this.chartService.getOpenOrders()
      .subscribe((data : any)=> {
        this.openOrdersItems = data.orders.filter(a => Math.abs(a.amount) > 1).sort((a,b) => b.date - a.date)
      })
      this.chartService.getBalance()
      .subscribe((data : any)=> {
        this.balance = data.balance
      })
  }

  getType(item){
    return (item.eur < 0 ? "BUY" : "SELL")
  }

  getAmount(item){
    return (item.device === "BTC" ? item.btc.toFixed(4) : item.xrp.toFixed(2))
  }

  getAbs(val){
    return Math.abs(val)
  }

  getBadgeClass(item){
    return (item.eur > 0 ? {'badge badge-success' : true} : {'badge badge-danger' : true})
  }
}
