import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CzechService } from 'src/app/services/czech.service';

@Component({
  selector: 'app-random-vocab',
  templateUrl: './random-vocab.component.html',
  styleUrls: ['./random-vocab.component.scss']
})
export class RandomVocabComponent implements OnInit {
  public categoryList = []
  public vocab = []
  public randomVocab = []
  public randomSize = 21;
  eventsSubject: Subject<void> = new Subject<void>();

  constructor(public cz : CzechService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cz.getCategories().toPromise().then(querySnapshot => {
      querySnapshot.forEach((doc) => {
        let newData = doc.data()
        this.categoryList.push(newData)
      })
      this.randomizeVocab()
    })
  }

  async randomizeVocab(){
    this.vocab = []
    for (let el of this.categoryList){
      this.vocab = this.vocab.concat(el.vocab)
    }
    this.randomVocab = this.shuffleArray(this.vocab).slice(0,this.randomSize)
    this.ref.detectChanges()
  }

  flipAllCards(){
    this.eventsSubject.next();
    this.ref.detectChanges()
  }

  shuffleVocab(){
    this.randomVocab = this.shuffleArray(this.randomVocab)
    this.flipAllCards()
    this.ref.detectChanges()
  }

  shuffleArray(arra1) {
    var ctr = arra1.length, temp, index;
    while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
    }
    return arra1;
  }

}
