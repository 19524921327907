import  { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { arrayUnion, arrayRemove } from '@angular/fire/firestore'
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class BibliService {
  public firstPageSize = 28
  public pageSize = 13

  constructor(private fire : AngularFirestore, private auth : AngularFireAuth, private fns : AngularFireFunctions) {}

  async mailRequested(mailSent, bookTitle, bookAuthor, bookFormat, bookDownloadURL, resFunction){
    let currentUser = (await this.auth.currentUser)
    let userMail = currentUser.email
    if(!mailSent){
      if (confirm(`En cliquant sur OK, un lien de téléchargement vous sera envoyé à cette adresse : ${userMail}.`)) {
        let mailButton = document.getElementById("mailButton")
        mailButton.classList.add("btnSecondary")
        mailButton.classList.remove('btnSecondaryLight')
        mailButton.classList.remove('actionBtnLight')
        this.sendByMail(userMail, bookTitle, bookAuthor, bookFormat, bookDownloadURL)
        this.logSendEmail(bookTitle, userMail)
        resFunction("ok")
      }
    } else {
      alert("Le livre vous a déjà été envoyé. Attendez quelques minutes puis vérifiez votre boîte mail.")
      resFunction("ko")
    }
  }

  sendByMail(destinataire, bookTitle, bookAuthor, bookFormat, bookDownloadURL){
    const callable = this.fns.httpsCallable('bookEmail');
    let paramMail = {
      destinataire : destinataire,
      subject: `Link to download ${bookTitle}`,
      bookTitle: bookTitle,
      bookAuthor: bookAuthor,
      bookFormat: bookFormat,
      bookLink: bookDownloadURL,
    }
    callable(paramMail).subscribe(data => {
      console.log(data)
    })
  }
  
  getInitBooks(){
    return this.fire.collection("bibliotheque", ref => ref
        .orderBy('bookAuthor')
        .orderBy('bookTitle')
        .limit(this.firstPageSize)
      ).get()
  }

  getBlibliData(){
    return this.fire.doc("general_data/bibli_data").valueChanges()
  }

  getNextBooks(last){
    return this.fire.collection("bibliotheque", ref => ref
        .orderBy('bookAuthor')
        .orderBy('bookTitle')
        .limit(this.pageSize)
        .startAfter(last)
      ).get()
  }

  getBooksByAuthor(author){
    if(author === "Inconnu") {
      return this.fire.collection("bibliotheque", ref => ref
        .where('bookAuthor', '==', "Inconnu")
      ).get()
    } else {
      return this.fire.collection("bibliotheque", ref => ref
        .where('bookAuthor', '==', author)
        .orderBy('bookTitle')
      ).get()
    }
  }

  getRecentBooks(){
    return this.fire.collection("bibliotheque", ref => ref
      .orderBy('date', 'desc')
      .limit(this.firstPageSize)
    ).get()
  }

  getMoreRecentBooks(last){
    return this.fire.collection("bibliotheque", ref => ref
      .orderBy('date', 'desc')
      .limit(this.pageSize)
      .startAfter(last)
    ).get()
}

  getBookById(id){
    return this.fire.doc("bibliotheque/" + id).valueChanges()
  }

  async logDownload(bookTitle){
    let currentUser = (await this.auth.currentUser)
    let userEmail = currentUser.email
    let displayName = currentUser.displayName
    this.fire.doc("bibliotheque_history/" + userEmail).set({
      history : arrayUnion({
        type : "Téléchargement",
        date : new Date(),
        bookTitle : bookTitle 
      })
    },
    {merge: true}
    ).then(() =>{
    }).catch(err => console.log(err))
  
    const callable = this.fns.httpsCallable('bookInfo');
    let paramMail = {
      message : `${bookTitle} a été téléchargé par ${displayName}`
    }
    callable(paramMail).subscribe(data => {
    })
  }

  async logSendEmail(bookTitle, email){
    let currentUser = (await this.auth.currentUser)
    let userEmail = currentUser.email
    let displayName = currentUser.displayName
    this.fire.doc("bibliotheque_history/" + userEmail).set({
      history : arrayUnion({
        type : "Mail",
        date : new Date(),
        bookTitle : bookTitle 
      })
    },
    {merge: true}
    ).then(() =>{
    }).catch(err => console.log(err))
    const callable = this.fns.httpsCallable('bookInfo');
      let paramMail = {
        message : `${bookTitle} a été envoyé par email à ${email}`
      }
      callable(paramMail).subscribe(data => {
    })
  }

  async updateBookData(id, dict){
    if(dict.bookAuthor){
      await this.fire.doc("general_data/bibli_data").update({
        authorList : arrayUnion(dict.bookAuthor)
      })
      return this.fire.doc("bibliotheque/" + id).set(dict, {merge : true})
    } else {
      return this.fire.doc("bibliotheque/" + id).set(dict, {merge : true})
    }
  }

  addToFavorite(id, mail){
    return this.fire.doc("bibliotheque/" + id).update({
      'favorite': arrayUnion(mail)
    });
  }

  removeFromFavorite(id, mail){
    return this.fire.doc("bibliotheque/" + id).update({
      'favorite': arrayRemove(mail)
    });
  }

  getBibliHistory(userEmail){
    return this.fire.doc("bibliotheque_history/" + userEmail).valueChanges()
  }

  getFavoriteUsers(userEmail){
    return this.fire.collection("bibliotheque", ref => ref
        .where('favorite', 'array-contains-any', [userEmail])
      ).get()
  }

}
