import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { ChartService } from '../../services/chart.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  public titre = "Aperçu global"
  public titreCamembert = "Répartition à date"
  public titreGrowth = "Croissance totale"
  public titreMonthGrowth = "Croissance mensuelle sur un an"
  public timeline : any[];

  public upGrowthArray = []
  public downGrowthArray = []
  
  public yearRange = []
  public yearGrowth = []

  public configGrowthAndMonthlyGrowth = {
    responsive: true, 
  }

  public configHist = {
    responsive: true, 
  }
  public configCam = {
    responsive: true, 
  }
  public layoutEpargneHistory = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    },
    // height:400,
    // autosize:true,
    automargin: true,
    barmode: 'stack',
    showlegend: false,
    legend: {
      x: 0,
      y: 0,
      yanchor : 'top',
      xanchor : 'left',
      orientation: 'h'
    }
  }
  public layoutGrowth = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    },
    height:300,
    autosize:true,
    automargin: true,
    barmode: 'stack',
    showlegend: false
  }
  public layoutMonthGrowth = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    },
    autosize:true,
    automargin: true,
    height:300,
    barmode: 'group',
    colorway : ['#3E537B', '#F75940', '#984A59', '#2D5C7F', '#6F5592', '#FF8F56', '#008080'],
    showlegend: true,
    legend: {
      x: 1,
      y: 0.05,
      yanchor : 'bottom',
      xanchor : 'right',
      orientation: 'h'
    }
  }
  public layoutEpargneCamembert = {
    margin: {
      l: 0,
      r: 0,
      b: 20,
      t: 20,
    },
    height:400,
    showlegend: false
  }
  public layoutLiqOrRisque = {
    margin: {
      l: 0,
      r: 5,
      b: 20,
      t: 20,
    },
    height:300,
    showlegend: true,
    autosize:true,
    automargin: true,
    barmode: 'stack',
    legend: {
      x: 0,
      y: 0.95,
      yanchor : 'bottom',
      xanchor : 'left',
      orientation: 'h'
    }
  }
  public chartDict = {}
  public allLabels = []
  public liquide = {
    "faiblement liquide" : ["october", "action_ws", "advize", "appart_otroko", "ceska_sporitelny", "kooperativa"],
    "moyennement liquide" : ["av_ca","generali_actif_general", 'generali_sci', 'generali_opci',  "pel", "action_sap", "term_deposit", "csob_bohatstvi", "csob_velmi_opatrny"],
    "fortement liquide" : ["compte_courant", "compte_courant_cz", "ldd", "crypto", "tickets_resto", "action_revolut"],
  }
  public risques = {
    faible : ["compte_courant", "compte_courant_cz" ,"ldd", "pel",  "av_ca", "generali_actif_general", "tickets_resto", "appart_otroko", "term_deposit", "csob_bohatstvi", "csob_velmi_opatrny"],
    moyen : ['generali_sci', 'generali_opci', "october",  "ceska_sporitelny", "kooperativa"],
    fort : ['action_ws', 'advize', "crypto", 'action_sap', "action_revolut"],
  }
  public colorLiqOrRisque = {
    faible : "#92D050",
    "faiblement liquide" : "#131a20", 
    moyen : "#FFC000", 
    "moyennement liquide" : "#334252",
    fort : "#FF5B5B",
    "fortement liquide" : "#8298b0",
  }

  public displayCamembert = true
  public displayingRisk = false
  public isFocused = false
  public lastAmount = 0
  public isDroppedDown = 0;

  public GraphEpargneHistory = {
    data:[],
    layout:this.layoutEpargneHistory,
    config:this.configHist,
  }

  public GraphCamembert = {
    data:[],
    layout:this.layoutEpargneCamembert,
    config:this.configCam
  }

  public GraphGrowth = {
    data:[],
    layout:this.layoutGrowth,
    config:this.configGrowthAndMonthlyGrowth
 }

  public GraphMonthGrowth = {
    data:[],
    layout:this.layoutMonthGrowth,
    config:this.configGrowthAndMonthlyGrowth
  }

  constructor(private chartService : ChartService, private ref: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.fillColors()
    let keys = Object.keys(this.chartDict)
    keys.forEach(key => {
      if (key !== "total_savings") {this.allLabels.push(this.chartDict[key].label)}
    })
    this.chartService.getEpargneHistory()
      .subscribe(data => {
        this.topoChart(data)
      })
    this.ref.detectChanges()
  }

  refreshDropDown(){
    this.ref.detectChanges()
  }

  topoChart(data) {
    this.timeline = data.map(({date}) => (new Date(date.seconds * 1000).toISOString()));
    this.fillChartDict(data)
    this.getLiquidity()
    // this.plotEpargneHistoryGraph()
    this.plotTodayOverallCamembert()
    this.plotOverallGrowth()
    this.plotMonthlyGrowth()
    this.lastAmount = this.chartDict["total_savings"].histValue[this.chartDict["total_savings"].histValue.length - 1]
    this.titre = `Aperçu global | ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(this.lastAmount)}`
    this.titreCamembert = "Répartition à date"
    this.titreGrowth = "Croissance globale"
    this.titreMonthGrowth = "Croissance mensuelle sur un an"
    this.ref.detectChanges()
  }

  getLiquidity(){
    this.GraphEpargneHistory.data = this.getLiqOrRisqHistory("liq")
    this.GraphEpargneHistory.layout = this.layoutLiqOrRisque
    this.GraphEpargneHistory.config = this.configHist
    window.dispatchEvent(new Event('resize'));
    this.ref.detectChanges()
  }

  getYearGrowth(){
    let ar = Array.from(this.yearGrowth)
    return ar.reverse()
  }

  getLiqOrRisqHistory(liqOrRisq){
    let objConcerne = {}
    if (liqOrRisq === "liq") {objConcerne = this.liquide} else {objConcerne = this.risques}
    let result = []
    let keys = Object.keys(objConcerne)
    keys.forEach(key => {
      let currentSum = []
      let typeLiq = objConcerne[key]
      typeLiq.forEach(typ => {
        if(typeLiq.indexOf(typ) == 0) {
          currentSum = this.chartDict[typ].histValue
        } else {
          currentSum = this.sumArray(currentSum, this.chartDict[typ].histValue)
        }
      })
      result.push({
        x : this.timeline,
        y : currentSum,
        // type : 'bar',
        stackgroup:'one',
        name : key,
        marker : {color : this.colorLiqOrRisque[key]}
      })
    })
    return result
  }



  getRisk(){
    this.displayingRisk = true
    this.titre = `Risk status`
    this.GraphEpargneHistory.data =this.getLiqOrRisqHistory("risk")
    // Plotly.react(
    //   this.elEpargneHistory.nativeElement,
    //   this.getLiqOrRisqHistory("risk"),
    //   this.layoutLiqOrRisque,
    //   this.configHist
    // )

    window.dispatchEvent(new Event('resize'));
    this.ref.detectChanges()
  }

  focusOn(label){
    // this.checkFocus()
    this.hidePanel()
    this.displayingRisk = false
    this.isFocused = false
    let index = this.allLabels.indexOf(label)
    if(label == "Risque"){
      this.getRisk()
      this.displayingRisk = true
      this.displayCamembert = false
      this.hidePanel()
      this.ref.detectChanges()
    } else if (label !== "Totale"){
      this.displayCamembert = false
      this.isFocused = true
      this.titreGrowth = `Croissance de ${label}`
      this.titreMonthGrowth = `Croissance mensuelle de ${label} sur un an`
      this.titreCamembert = "Répartition à date"
      this.GraphEpargneHistory.data =this.getDataHistory(index)
      this.GraphGrowth.data = this.generateGrowth(index)
      this.GraphMonthGrowth.data = this.generateMonthlyAndYearlyGrowth(index)
      this.ref.detectChanges()
      // Plotly.react(
      //   this.elEpargneHistory.nativeElement,
      //   this.getDataHistory(index),
      //   this.layoutEpargneHistory,
      //   this.configHist
      // ) 
      // Plotly.react(
      //   this.elGrowth.nativeElement,
      //   this.generateGrowth(index),
      //   this.layoutGrowth,
      //   this.configGrowthAndMonthlyGrowth
      // )
      // this.ref.detectChanges()
      //  Plotly.react(
      //     this.elMonthGrowth.nativeElement,
      //     this.generateMonthlyAndYearlyGrowth(index),
      //     this.layoutMonthGrowth,
      //     this.configGrowthAndMonthlyGrowth
      //   )
        this.titre = `${label} | ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(this.lastAmount)}`
        window.dispatchEvent(new Event('resize'));
        this.ref.detectChanges()
    } else if (label == "Totale") {
      this.displayCamembert = true
      this.plotEpargneHistoryGraph()
      this.getLiquidity()
      this.plotTodayOverallCamembert()
      this.plotOverallGrowth()
      this.plotMonthlyGrowth()
      this.lastAmount = this.chartDict["total_savings"].histValue[this.chartDict["total_savings"].histValue.length - 1]
      this.ref.detectChanges()
      this.titre = `Aperçu global | ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0}).format(this.lastAmount)}`
      window.dispatchEvent(new Event('resize'));
    }
    this.ref.detectChanges()
  }

  getDataHistory(index) {
    let result = []
    let keys = Object.keys(this.chartDict)
    let key = keys[index]
    result.push({
      x : this.timeline,
      y : this.chartDict[key].histValue,
      mode: 'lines+markers',
      type : 'scatter',
      name : this.chartDict[key].label,
      marker : {color : this.chartDict[key].color}
    })
    this.lastAmount = this.chartDict[key].histValue[this.chartDict[key].histValue.length - 1]
    return result
  }
  
  plotEpargneHistoryGraph(){
    this.GraphEpargneHistory.data = this.generateDataHistory()
    this.ref.detectChanges()
  }

  plotTodayOverallCamembert(){
    this.GraphCamembert.data = this.generateDataCamembert()
    this.ref.detectChanges()
  }

  plotOverallGrowth(){
    this.GraphGrowth.data = this.generateGrowth(this.allLabels.length)
    this.ref.detectChanges()
  }

  plotMonthlyGrowth(){
    this.GraphMonthGrowth.data = this.generateMonthlyAndYearlyGrowth(this.allLabels.length)
    this.ref.detectChanges()
  }

  generateDataCamembert(){
    let result = [{
      values : [],
      labels : [],
      marker : {colors : []},
      hoverinfo: 'label+value',
      direction : "clockwise",
      type : "pie",
      automargin: true
    }]
    let keys = Object.keys(this.chartDict)
    keys.forEach(key => {
      if (key !== "total_savings" && this.chartDict[key].todayValue !== 0){
        result[0].values.push(this.chartDict[key].todayValue)
        result[0].labels.push(this.chartDict[key].label)
        result[0].marker.colors.push(this.chartDict[key].color)
      } 
    })
    return result
  }

  generateDataHistory(){
    let result = []
    let keys = Object.keys(this.chartDict)
    keys.forEach(key => {
      if (key !== "total_savings"){
        result.push({
          x : this.timeline,
          y : this.chartDict[key].histValue,
          type : 'bar',
          name : this.chartDict[key].label,
          marker : {color : this.chartDict[key].color}
        })
        this.lastAmount = this.chartDict[key].histValue[this.chartDict[key].histValue.length - 1]
      } else {
        result.push({
          x : this.timeline,
          y : this.chartDict[key].histValue,
          mode: 'lines+markers',
          type : 'scatter',
          name : this.chartDict[key].label,
          marker : {color : this.chartDict[key].color}
        })
        this.lastAmount = this.chartDict[key].histValue[this.chartDict[key].histValue.length - 1]
      }
    })
    return result
  }

  generateMonthlyAndYearlyGrowth(index){
    let growthOnIndex = this.generateGrowth(index)
    let dataMonthlyGrowth = []
    this.yearRange = []
    let start = new Date(this.timeline[1]).getFullYear()
    let end = new Date(this.timeline[this.timeline.length - 1]).getFullYear()
    for (let i = end ; i >= start ; i --){
      dataMonthlyGrowth.push({
        x : [],
        y : [],
        name : i,
        type : "bar"
      })
      this.yearRange.push(i)
    }
    for (let j = 0 ; j < growthOnIndex[0].y.length ; j ++){
      let elDate = new Date(growthOnIndex[0].x[j])
      elDate.setDate(elDate.getDate() - 15)
      let elDateYear = elDate.getFullYear()
      let elDateMonth = elDate.toLocaleString('default', { month: 'long' });
      let elDateMonthCap = elDateMonth.charAt(0).toUpperCase() + elDateMonth.slice(1)
      let indexData = end - elDateYear
      dataMonthlyGrowth[indexData].x.push(elDateMonthCap)
      dataMonthlyGrowth[indexData].y.push(growthOnIndex[0].y[j])
    }
    this.updateYearlyGrowth(dataMonthlyGrowth)
    return dataMonthlyGrowth
  }

  updateYearlyGrowth(monthlyGrowth){
    this.yearGrowth = []
    this.yearRange.forEach(el => {
      this.yearGrowth.push({
        year : el,
        sum : 0,
        nb : 0,
        avg : 0
      })
    })
    monthlyGrowth.forEach(el => {
      let yearValue = el.name
      let index = this.yearGrowth.findIndex(years => years.year == yearValue)
      el.y.forEach(growth => {
        this.yearGrowth[index].sum += growth
        this.yearGrowth[index].nb ++
      })
      if(this.yearGrowth[index].nb !== 0){
        this.yearGrowth[index].avg = (this.yearGrowth[index].sum / this.yearGrowth[index].nb).toFixed()
      }
    })
  }

  generateGrowth(index){
    let result = []
    let key = Object.keys(this.chartDict)[index]
    let growthValues = []
    for (let i = 1 ; i < this.chartDict[key].histValue.length ; i++){
      growthValues.push(this.chartDict[key].histValue[i] - this.chartDict[key].histValue[i-1])
    }
    result.push({
      x : this.timeline.slice(1, this.timeline.length),
      y : growthValues,
      mode: 'lines+markers',
      type : 'scatter',
      name : `Growth of ${this.chartDict[key].label}`,
      marker : {color : this.chartDict[key].color}
    })
    return result
  }

  fillChartDict(data){
    this.chartDict['appart_otroko'].histValue = data.map( ({appart_otroko})=> appart_otroko == null ? 0 : Math.floor(appart_otroko))
    this.chartDict['compte_courant_cz'].histValue = data.map( ({compte_courant_cz})=> compte_courant_cz == null ? 0 : Math.floor(compte_courant_cz))
    this.chartDict['ceska_sporitelny'].histValue = data.map( ({ceska_sporitelny})=> ceska_sporitelny == null ? 0 : Math.floor(ceska_sporitelny))
    this.chartDict['kooperativa'].histValue = data.map( ({kooperativa})=> kooperativa == null ? 0 : Math.floor(kooperativa))
    this.chartDict['compte_courant'].histValue = data.map( ({compte_courant})=> Math.floor(compte_courant))
    this.chartDict['ldd'].histValue = data.map( ({ldd})=> Math.floor(ldd))
    this.chartDict['pel'].histValue = data.map( ({pel})=> Math.floor(pel))
    this.chartDict['av_ca'].histValue = data.map( ({av_ca})=> Math.floor(av_ca))
    this.chartDict['generali_actif_general'].histValue = data.map( ({generali_actif_general})=> Math.floor(generali_actif_general))
    this.chartDict['generali_sci'].histValue = data.map( ({generali_sci})=> Math.floor(generali_sci))
    this.chartDict['generali_opci'].histValue = data.map( ({generali_opci})=> Math.floor(generali_opci))
    this.chartDict['october'].histValue = data.map( ({october})=> Math.floor(october))
    this.chartDict['action_ws'].histValue = data.map( ({action_ws})=> Math.floor(action_ws))
    this.chartDict['action_sap'].histValue = data.map( ({action_sap})=> action_sap == null ? 0 : Math.floor(action_sap))
    this.chartDict['action_revolut'].histValue = data.map( ({action_revolut})=> action_revolut == null ? 0 : Math.floor(action_revolut))
    this.chartDict['advize'].histValue = data.map( ({advize})=> Math.floor(advize))
    this.chartDict['crypto'].histValue = data.map( ({crypto})=> Math.floor(crypto))
    this.chartDict['term_deposit'].histValue = data.map( ({term_deposit})=> term_deposit == null ? 0 : Math.floor(term_deposit))
    this.chartDict['csob_bohatstvi'].histValue = data.map( ({csob_bohatstvi})=> csob_bohatstvi == null ? 0 : Math.floor(csob_bohatstvi))
    this.chartDict['csob_velmi_opatrny'].histValue = data.map( ({csob_velmi_opatrny})=> csob_velmi_opatrny == null ? 0 : Math.floor(csob_velmi_opatrny))
    this.chartDict['tickets_resto'].histValue = data.map( ({tickets_resto})=> Math.floor(tickets_resto))
    this.chartDict['total_savings'].histValue = this.getTotalSavings()
    this.chartDict['appart_otroko'].todayValue = this.last(this.chartDict['appart_otroko'].histValue)
    this.chartDict['compte_courant'].todayValue = this.last(this.chartDict['compte_courant'].histValue)
    this.chartDict['compte_courant_cz'].todayValue = this.last(this.chartDict['compte_courant_cz'].histValue)
    this.chartDict['ldd'].todayValue = this.last(this.chartDict['ldd'].histValue)
    this.chartDict['pel'].todayValue = this.last(this.chartDict['pel'].histValue)
    this.chartDict['av_ca'].todayValue = this.last(this.chartDict['av_ca'].histValue)
    this.chartDict['generali_actif_general'].todayValue = this.last(this.chartDict['generali_actif_general'].histValue)
    this.chartDict['generali_sci'].todayValue = this.last(this.chartDict['generali_sci'].histValue)
    this.chartDict['generali_opci'].todayValue = this.last(this.chartDict['generali_opci'].histValue)
    this.chartDict['october'].todayValue = this.last(this.chartDict['october'].histValue)
    this.chartDict['action_ws'].todayValue = this.last(this.chartDict['action_ws'].histValue)
    this.chartDict['action_sap'].todayValue = this.last(this.chartDict['action_sap'].histValue)
    this.chartDict['action_revolut'].todayValue = this.last(this.chartDict['action_revolut'].histValue)
    this.chartDict['term_deposit'].todayValue = this.last(this.chartDict['term_deposit'].histValue)
    this.chartDict['csob_bohatstvi'].todayValue = this.last(this.chartDict['csob_bohatstvi'].histValue)
    this.chartDict['csob_velmi_opatrny'].todayValue = this.last(this.chartDict['csob_velmi_opatrny'].histValue)
    this.chartDict['advize'].todayValue = this.last(this.chartDict['advize'].histValue)
    this.chartDict['crypto'].todayValue = this.last(this.chartDict['crypto'].histValue)
    this.chartDict['tickets_resto'].todayValue = this.last(this.chartDict['tickets_resto'].histValue)
    this.chartDict['kooperativa'].todayValue = this.last(this.chartDict['kooperativa'].histValue)
    this.chartDict['ceska_sporitelny'].todayValue = this.last(this.chartDict['ceska_sporitelny'].histValue)
    this.calculateGrowths()
  }

  calculateGrowths() {
    for(let i = 0; i < this.allLabels.length ; i ++){
      let lastKeyGrowth = this.generateGrowth(i)[0]['y'].slice(-1)[0]
      if (lastKeyGrowth > 0) {
        this.upGrowthArray.push({
          label : this.chartDict[Object.keys(this.chartDict)[i]].label,
          growth : lastKeyGrowth
        })
      } else if (lastKeyGrowth < 0){
        this.downGrowthArray.push({
          label : this.chartDict[Object.keys(this.chartDict)[i]].label,
          growth : lastKeyGrowth
        })
      }
    }
    this.upGrowthArray.sort((a,b)=>b.growth - a.growth)
    this.downGrowthArray.sort((a,b)=>a.growth - b.growth)
  }
  
  getTotalSavings(): any[] {
    let result = [];
    for(let i = 0; i< this.timeline.length; i++){
      result.push(this.chartDict['appart_otroko'].histValue[i] + this.chartDict['compte_courant'].histValue[i] + this.chartDict['compte_courant_cz'].histValue[i] + this.chartDict['ldd'].histValue[i] + this.chartDict['pel'].histValue[i] + this.chartDict['av_ca'].histValue[i] + this.chartDict['generali_actif_general'].histValue[i] + this.chartDict['generali_opci'].histValue[i] + this.chartDict['generali_sci'].histValue[i] + this.chartDict['october'].histValue[i] + this.chartDict['action_ws'].histValue[i] + this.chartDict['action_sap'].histValue[i] + this.chartDict['advize'].histValue[i] + this.chartDict['crypto'].histValue[i] + this.chartDict['tickets_resto'].histValue[i] + this.chartDict['term_deposit'].histValue[i] + this.chartDict['csob_bohatstvi'].histValue[i] + this.chartDict['csob_velmi_opatrny'].histValue[i] + this.chartDict['action_revolut'].histValue[i] + this.chartDict['ceska_sporitelny'].histValue[i] + this.chartDict['kooperativa'].histValue[i])
    }
    return result
  }

  last(array) {
    return array[array.length - 1];
  }
  
  getClassCam() {
    if(this.displayCamembert){
      return {'showCam': true}
    } else {
      return {'hideCam': true}
    }
  }

  getClassRest() {
    if(this.displayingRisk || this.isFocused){
      return {'hideRest': true}
    } else {
      return {'showRest': true}
    }
  }

  sumArray(a, b) {
    var c = [];
    for (var i = 0; i < Math.max(a.length, b.length); i++) {
      c.push((a[i] || 0) + (b[i] || 0));
    }
    return c;
  }

  
  showPanel(){
    let menu = document.getElementById("focusMenu");
    menu.classList.add("toggleMenu")
    this.isDroppedDown = 1
  }

  checkFocus(){
    if(this.isDroppedDown == 1){
      this.hidePanel()
    } else {
      this.showPanel()
    }
  }

  hidePanel(){
    let menu = document.getElementById("focusMenu");
    menu.classList.remove("toggleMenu")
    this.isDroppedDown = 0
  }

  lerp = function(a, b, u) {
    return (1 - u) * a + u * b;
  };

  fade = function(start, end, nbSteps) {
    let step_u = 1.0 / nbSteps;
    let u = 0.0;
    let result = []
    for (let i = 0 ; i <= nbSteps ; i ++){
      let r = Math.round(this.lerp(start.r, end.r, u));
      let g = Math.round(this.lerp(start.g, end.g, u));
      let b = Math.round(this.lerp(start.b, end.b, u));
      let colorname = 'rgb(' + r + ',' + g + ',' + b + ')';
      u += step_u;
      result.push(colorname)
    }
    return result
};

  fillColors(){
    let colorArrayGoodLiquidity = this.fade({r:121, g: 250, b: 197}, {r:0, g: 138, b: 93}, 7)
    let colorArrayMediumLiquidity = this.fade({r:0, g: 183, b: 255}, {r:59, g: 72, b: 86}, 13)
    let colorArrayLowLiquidity = this.fade({r:255, g: 133, b: 167}, {r:154, g: 0, b: 0}, 3)
    this.chartDict =   {
      compte_courant : {
        histValue : [],
        todayValue : [],
        label : "Compte courant",
        color : colorArrayGoodLiquidity[0]
      },
      compte_courant_cz : {
        histValue : [],
        todayValue : [],
        label : "Compte courant CZ",
        color : colorArrayGoodLiquidity[1]
      },
      ldd : {
        histValue : [],
        todayValue : [],
        label : "Livret A",
        color : colorArrayGoodLiquidity[2]
      },
      // ldd_lucie : {
      //   histValue : [],
      //   todayValue : [],
      //   label : "Livret A Lucie",
      //   color : colorArrayGoodLiquidity[3]
      // },
      pel : {
        histValue : [],
        todayValue : [],
        label : "PEL",
        color : colorArrayMediumLiquidity[0]
      },
      // pel_lucie : {
      //   histValue : [],
      //   todayValue : [],
      //   label : "PEL Lucie",
      //   color : colorArrayMediumLiquidity[1]
      // },
      av_ca : {
        histValue : [],
        todayValue : [],
        label : "Assurance vie CA",
        color : colorArrayMediumLiquidity[2]
      },
      generali_actif_general : {
        histValue : [],
        todayValue : [],
        label : "Generali Actif General",
        color : colorArrayMediumLiquidity[3]
      },
      generali_sci : {
        histValue : [],
        todayValue : [],
        label : "Generali SCI",
        color : colorArrayMediumLiquidity[4]
      },
      generali_opci : {
        histValue : [],
        todayValue : [],
        label : "Generali OPCI",
        color : colorArrayMediumLiquidity[5]
      },
      // av_lucie : {
      //   histValue : [],
      //   todayValue : [],
      //   label : "Assurance vie Lucie",
      //   color : colorArrayMediumLiquidity[6]
      // },
      advize : {
        histValue : [],
        todayValue : [],
        label : "Advize",
        color : colorArrayLowLiquidity[0]
      },
      october : {
        histValue : [],
        todayValue : [],
        label : "October",
        color : colorArrayLowLiquidity[1]
      },
      action_ws : {
        histValue : [],
        todayValue : [],
        label : "Action Wavestone",
        color : colorArrayLowLiquidity[2]
      },
      action_sap : {
        histValue : [],
        todayValue : [],
        label : "Action SAP",
        color : colorArrayMediumLiquidity[7]
      },
      action_revolut : {
        histValue : [],
        todayValue : [],
        label : "Actions Revolut",
        color : colorArrayGoodLiquidity[6]
      },
      crypto : {
        histValue : [],
        todayValue : [],
        label : "Cryptocurrency",
        color : colorArrayGoodLiquidity[4]
      },
      tickets_resto : {
        histValue : [],
        todayValue : [],
        label : "Tickets restaurant",
        color : colorArrayGoodLiquidity[5]
      },
      appart_otroko : {
        histValue : [],
        todayValue : [],
        label : "Appart Otrokovice",
        color : '#F9F871'
      },
      term_deposit : {
        histValue : [],
        todayValue : [],
        label : "Term deposit",
        color : colorArrayMediumLiquidity[8]
      },
      csob_bohatstvi : {
        histValue : [],
        todayValue : [],
        label : "CSOB Bohatstvi",
        color : colorArrayMediumLiquidity[9]
      },
      csob_velmi_opatrny : {
        histValue : [],
        todayValue : [],
        label : "CSOB Velmi Opatrny",
        color : colorArrayMediumLiquidity[10]
      },
      ceska_sporitelny : {
        histValue : [],
        todayValue : [],
        label : "Ceska Sporitelny",
        color : colorArrayMediumLiquidity[11]
      },
      kooperativa : {
        histValue : [],
        todayValue : [],
        label : "Kooperativa",
        color : colorArrayMediumLiquidity[12]
      },      
      total_savings : {
        histValue : [],
        todayValue : [],
        label : "Total",
        color : '#f75840'
      },
    }
  
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if(targetElement.id !== "dropdownButton"){
      this.hidePanel()
    }
  }

}
