<div class="headerCat">
    <div class="grid-item">
        <a routerLink="/czech"><button type="button" class="btnPerso btnGrey btnSmall">←</button></a>
    </div>
    <div class="grid-item">
        <h2>Révision aléatoire</h2>
    </div>
    <div class="grid-item buttonList">
        <button (click)="randomizeVocab()" class="btnPerso btnPrimary btnSmall" >Nouveau batch</button>
        <button (click)="shuffleVocab()" class="btnPerso btnPrimary btnSmall">Mélanger</button>
    </div>
</div>

<div class="wordGrid">
    <div *ngFor="let item of randomVocab; let i = index">
            <app-vocab-word
            [fr]="item.fr"
            [cz]="item.cz"
            [events]="eventsSubject.asObservable()">    
            </app-vocab-word>
    </div>
</div>

<div class="endButton">
    <button (click)="shuffleVocab()" class="btnPerso btnPrimary" >Mélanger</button>
</div>
