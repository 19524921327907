<div class="headerCat">
    <div class="grid-item">
        <button type="button" routerLink="/czech" class="btnPerso btnGrey btnSmall">←</button>
    </div>
    <div class="grid-item">
        <h2>{{titleFR}} / {{titleCZ}}</h2>
    </div>
    <div class="grid-item buttonList">
        <button (click)="shuffleVocab()" class="btnPerso btnPrimary btnSmall">Mélanger</button>
    </div>
</div>

<div class="wordGrid">
    <div *ngFor="let item of vocab; let i = index">
            <app-vocab-word
            [fr]="item.fr"
            [cz]="item.cz"
            [events]="eventsSubject.asObservable()">    
            </app-vocab-word>
    </div>
</div>

<div class="endButton">
    <button (click)="shuffleVocab()" class="btnPerso btnPrimary" >Mélanger</button>
</div>