import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PepiteService } from 'src/app/services/pepite.service';

@Component({
  selector: 'app-pepite-detail',
  templateUrl: './pepite-detail.component.html',
  styleUrls: ['./pepite-detail.component.scss']
})
export class PepiteDetailComponent implements OnInit {
  date : string;
  illustration : string;
  location : string;
  owner : string;
  randNumber : string;
  description : string;
  title : string;
  type:string;
  customId:string;

  constructor(private pep : PepiteService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.customId  = this.route.snapshot.params['id'];
    this.pep.getPepitebyId(this.customId).subscribe((data : any)=> {
      console.log(data)
      if(data.hasOwnProperty("date")) {this.date = data.date}
      if(data.hasOwnProperty("illustration")) {this.illustration = data.illustration}
      if(data.hasOwnProperty("location")) {this.location = data.location}
      if(data.hasOwnProperty("owner")) {this.owner = data.owner}
      if(data.hasOwnProperty("description")) {this.description = data.description}
      if(data.hasOwnProperty("title")) {this.title = data.title}
      if(data.hasOwnProperty("type")) {this.type = data.type}
      if(data.hasOwnProperty("randNumber")) {this.randNumber = data.randNumber}
    })
  }

}
