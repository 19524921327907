import { ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BibliService } from '../../services/bibli.service';

@Component({
  selector: 'app-edit-bibli',
  templateUrl: './edit-bibli.component.html',
  styleUrls: ['./edit-bibli.component.scss']
})
export class EditBibliComponent implements OnInit {
  public dataForm = this.formBuilder.group({
    bookName : [''],
    bookCover : [''],
    bookAuthor : [''],
    bookTitle : [''],
    bookSummary : [''],
    bookFormat : ['']
  })
  public id;
  public bookName = ""
  public bookCover = ""
  public bookAuthor = ""
  public bookTitle = ""
  public bookSummary = ""
  public bookFormat = ""
  public downloadURL;
  public reussi = false;
  public searchURL = ""
  public isCollapsed = true;

  constructor(private ref: ChangeDetectorRef, private ngZone:NgZone, private router : Router, private formBuilder: FormBuilder, private route: ActivatedRoute, private bibli : BibliService) { }

  ngOnInit() {
    this.id  = this.route.snapshot.params['id'];
    this.bibli.getBookById(this.id).subscribe((data : any)=> {
      if(data.hasOwnProperty("bookName")) {this.bookName = data.bookName}
      if(data.hasOwnProperty("bookCover")) {this.bookCover = data.bookCover}
      if(data.hasOwnProperty("bookAuthor")) {this.bookAuthor = data.bookAuthor}
      if(data.hasOwnProperty("bookTitle")) {this.bookTitle = data.bookTitle}
      if(data.hasOwnProperty("bookSummary")) {this.bookSummary = data.bookSummary}
      if(data.hasOwnProperty("format")) {this.bookFormat = data.format}
      if(data.hasOwnProperty("downloadURL")) {this.downloadURL = data.downloadURL}
      this.dataForm.patchValue({
        bookName : this.bookName,
        bookCover : this.bookCover,
        bookAuthor : this.bookAuthor,
        bookTitle : this.bookTitle,
        bookSummary : this.bookSummary,
        bookFormat : this.bookFormat
      })
      this.searchURL = "https://www.bing.com/images/search?q=" + encodeURIComponent(this.bookName + " " + this.bookAuthor) + "&form=QBLH&sp=-1&pq=le+vieil+homme+et+la+mer+couvertu&sc=0-33&qs=n&cvid=C54F7AE710D34DC383BCB5C51EA725C0&first=1&tsc=ImageHoverTitle"
      this.ref.detectChanges()
    })
    this.onChange()
    this.ref.detectChanges()
  }

  onSubmit(dataPoint) {
    let dict = {
      bookName : dataPoint.bookName,
      bookCover : dataPoint.bookCover,
      bookAuthor : dataPoint.bookAuthor,
      bookTitle : dataPoint.bookTitle,
      bookSummary : dataPoint.bookSummary,
      format : dataPoint.bookFormat
    }
    this.bibli.updateBookData(this.id, dict).then(() => {
      this.ngZone.run(()=>this.router.navigate(['/bibliotheque']))
    })
  }

  onChange() {
    this.dataForm.get('bookName').valueChanges.subscribe(val => {
      this.dataForm.controls['bookTitle'].setValue(val)
    })
  }

  collapse() {
    let collapseDiv = document.getElementById("my-div")
    if(this.isCollapsed){
      collapseDiv.classList.remove("collapseDiv")
    } else {
      collapseDiv.classList.add("collapseDiv")
    }
    this.isCollapsed = !this.isCollapsed
  }

}
