import { Component, NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import algoliasearch from 'algoliasearch/lite';
import { BookComponent } from '../book/book.component';


const searchClient = algoliasearch('Z2Z1T4F54J','46f77d24a06c7a7e7b64d83a6a5c3946');

@Component({
  selector: 'app-search-books',
  templateUrl: './search-books.component.html',
  styleUrls: ['./search-books.component.scss']
})
export class SearchBooksComponent {
  searchClient = algoliasearch('Z2Z1T4F54J','46f77d24a06c7a7e7b64d83a6a5c3946');

  config = {
    indexName: 'bibliothèque',
    searchClient
  };
  constructor(public auth: AngularFireAuth) { }

  checkIfFavorite(item, userMail){
    return (typeof item['favorite'] !== 'undefined')?item['favorite'].includes(userMail):false
  }


}
