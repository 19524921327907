<div class="container pt-1">
    <div class="row align-items-center">
        <div class="col-10 px-1">
            <a [routerLink]="[customId]">
                <p class="align-baseline mb-0">📖 {{bookAuthor}} | {{ bookName }} | format {{bookFormat}} </p>
            </a>
        </div>
        <div class="col-2 pe-0">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-light btn-sm me-1" (click)="downloadBook()" data-toggle="tooltip" data-placement="top" title="Télécharger">↓</button>
                <a [routerLink]="['/editLivre/', customId]"><button type="button" class="btn btn-light btn-sm" data-toggle="tooltip" data-placement="top" title="Modifier">✎</button></a>
            </div>
        </div>
    </div>
    <hr class="mt-1">

</div>
