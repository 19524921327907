import { Component } from '@angular/core';
import { ColorPreferenceService } from 'src/app/services/color-preference.service';
import { FunctionsService } from 'src/app/services/functions.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  public userPicUrl = 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png'
  public colorMode = 'light'

constructor(public fnc : FunctionsService, public colorPref : ColorPreferenceService) {
    fnc.loggedIn = !!sessionStorage.getItem('user')
    this.updateUserPicUrl()
    colorPref.checkInitialColorMode()
  }

  updateUserPicUrl(){
   
    return this.fnc.getUserPic()
  }

  loginBtnText(){
    if (this.thereIsALoggedInUser()){
      return "Log out"
    } else {
      return "Log in"
    }
  }
 
  getClassLogin(){
    if (this.thereIsALoggedInUser()){
      return ({'btnPerso btnSecondary loginBtn' : true})
    } else {
      return ({'btnPerso btnPrimary loginBtn' : true})
    }
  }

  thereIsALoggedInUser(){
    let curUser = (this.fnc.getUser())
    if(curUser !== null && (typeof curUser !== 'undefined')){
      return true
    } else{
      return false
    }
    // return (typeof this.fnc.getUser() !== undefined && this.fnc.getUser().email.length > 5)
  }

 
}
