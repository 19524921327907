
<div class="container">
    <div class="pt-1">
    <h4>Current balance :</h4>
    <div class="row">
      <div class="col">
        <p class="font-weight-bold">Devise</p>
      </div>
      <div class="col">
        <p class="font-weight-bold">Balance</p>
      </div>
      <div class="col">
        <p class="font-weight-bold">Available</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="font-weight-bold">EUR :</p>
      </div>
      <div class="col">
      {{this.balance.eur_balance}}
      </div>
      <div class="col">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="font-weight-bold">XRP :</p>
      </div>
      <div class="col">
      {{this.balance.xrp_balance.toFixed()}}
      </div>
      <div class="col">
      {{this.balance.xrp_available.toFixed()}}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="font-weight-bold">BTC :</p>
      </div>
      <div class="col">
      {{this.balance.btc_balance.toFixed(3)}}
      </div>
      <div class="col">
      {{this.balance.btc_available.toFixed(3)}}
      </div>
    </div>
    </div>
  </div>
  
  <div class="container">
    <div class="pt-4">
    <h4>Daily XRP Ticker</h4>
    <app-chart-ticker></app-chart-ticker>
    </div>
  </div>
  <!-- <ul class="list-group">
      <li *ngFor="let item of transactionItems"
      [ngClass]="{'list-group-item': true,
      'list-group-item-success': item.eur > 0,
      'list-group-item-danger': item.eur < 0}"
      >
      <span [ngClass]="getBadgeClass(item)">{{ getType(item) }}</span>    
      <span class="badge badge-secondary">{{ item.device }}</span> for {{ getAbs(item.eur.toFixed(2)) }} € -- {{ item.date.seconds * 1000  | date: 'dd/MM/yyyy hh:mm'}} :  {{ getAbs(getAmount(item))}}</li>
    </ul> -->