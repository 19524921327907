<h2>Enter a new data point</h2>
<div class="mainContent">
    <form [formGroup]="dataForm" (ngSubmit)="onSubmit(dataForm.value)">
        <fieldset class="mainFormWithSuffix">
            
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Date</span>
            </div>
            <input id="datePicker" name="date" type="date" formControlName="date" class="userInput">
            <div class="nothing">
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Current account</span>
            </div>
            <input id="current_account" name="current_account" type="number" formControlName="current_account" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>
      
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Savings</span>
            </div>
            <input id="savings_account" name="savings_account" type="number" formControlName="savings_account" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>
                        
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Savings Property</span>
            </div>
            <input id="savings_property" name="savings_property" type="number" formControlName="savings_property" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>

            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Pension Savings</span>
            </div>
            <input id="pension_savings" name="pension_savings" type="number" formControlName="pension_savings" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>
                        
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Stock SAP</span>
            </div>
            <input id="sap_stock" name="sap_stock" type="number" formControlName="sap_stock" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>
                        
            <div class="prefix">
                <span class="prefixText" id="basic-addon1">Stocks Revolut</span>
            </div>
            <input id="action_revolut" name="action_revolut" type="number" formControlName="action_revolut" class="userInputPreSuffix">
            <div class="suffix">
                <span class="suffixText" id="basic-addon1">CZK</span>
            </div>
        </fieldset>

        <div class="btnList">
            <button type="submit" class="btnPerso btnSecondary flexitem">Save</button>
            <h5 *ngIf="reussi" class = "flexitem">Done!</h5>
            <button type='reset' routerLink="/moneyDeni" class="btnPerso btnGreyLight flexitem">Cancel</button>
        </div>
    </form>
</div>