<h2 id="titreAccueil">Rugby Games</h2>
<h2 *ngIf="!loaded">Récupération des matchs en cours</h2>
<h2 *ngIf="loaded && top14.length == 0 && championsCup.length == 0 && sixNations.length == 0">Pas de match disponible aujourd'hui</h2>
<h4 *ngIf="loaded" class="textPrimary mainTitle">TOP 14</h4>
<div class="matchGrid">
    <app-match *ngFor="let item of top14"
        [matchName]="item.matchName"
        [matchLink]="item.matchLink"
        [matchDateStr]="item.matchDateStr"
        [matchLogos]="item.matchLogos"
        [matchRealDate]="item.matchRealDate"
        [matchImg]="item.matchImg"
        [matchDelay]='item.delay'>
    </app-match>
</div>

<div *ngIf="loaded" class="btnCollapse">
    <button type="button" class="btnPerso btnPrimaryLight" (click)="collapse()">{{ClassementButtonTitle}}</button>
</div>
<div *ngIf="loaded" class="classement" id="collapseExample">
    <div id="my-div" class="collapseDiv">
        <iframe id="my-iframe" width="100%" height="500" frameborder="0" allowfullscreen  src="https://www.rugbyrama.fr/rugby/top-14/standing.shtml"></iframe>
    </div>
</div>

<div *ngIf="championsCup.length > 0">
    <h4 class ="textSecondary mainTitle">Champions Cup</h4>
    <div class="matchGrid">
        <app-match *ngFor="let item of championsCup"
            [matchName]="item.matchName"
            [matchLink]="item.matchLink"
            [matchDateStr]="item.matchDateStr"
            [matchLogos]="item.matchLogos"
            [matchRealDate]="item.matchRealDate"
            [matchImg]="item.matchImg"
            [matchDelay]='item.delay'>
        </app-match>
    </div>
</div>

<div *ngIf="sixNations.length > 0">
    <h4 class ="textPrimary mainTitle">6 Nations</h4>
    <div class="matchGrid">
        <app-match *ngFor="let item of sixNations"
            [matchName]="item.matchName"
            [matchLink]="item.matchLink"
            [matchDateStr]="item.matchDateStr"
            [matchRealDate]="item.matchRealDate"
            [matchLogos]="item.matchLogos"
            [matchImg]="item.matchImg"
            [matchDelay]='item.delay'>
        </app-match>
    </div>
</div>
